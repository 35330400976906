import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import momentDate from 'moment';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";

import axios from 'api/axios';


const ContextMissionDetailsInvoice = createContext();

const MissionDetailsInvoiceProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const { generalRef } = useContext(ContextMissionDetails);
  const { invoiceRef } = useContext(ContextMissionDetails);
  const { getMissionDetail } = useContext(ContextMissionDetails);

  //API URL
  const MISSION_URL = 'mission';

  //generic
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { setSnackErrorMsg } = useContext(ContextBlock);

  //workflow
  const { canWrite } = useContext(ContextMissionWorkflow);
  const { controlFieldsData } = useContext(ContextMissionWorkflow);
  const { checkBlockOnWorkflowError } = useContext(ContextMissionWorkflow);
  const {controlFieldsError, setControlFieldsError} = useContext(ContextMissionWorkflow);

  const {controlFieldsErrorTruncate} = useContext(ContextMissionWorkflow);
  const {controlFieldsInvoice, setControlFieldsInvoice} = useContext(ContextMissionWorkflow);

  //mission general data
  const {general, setGeneral} = useContext(ContextMissionDetails);
  const { idMission, setIdMision } = useContext(ContextMissionDetails);

  const {invoicesNote, setInvoicesNote} = useContext(ContextMissionDetails);

  //treament
  const [reloadData, setReloadData] = useState(0);
  const [oldReloadData, setOldReloadData] = useState(0);
  const [blockCurrentEdit, setBlockCurrentEdit] = useState({});
  const [blockCurrentSave, setBlockCurrentSave] = useState({});
  
  const { setDoneBlockSave } = useContext(ContextBlock);
  const { setErrorBlockSave } = useContext(ContextBlock);
  const { setReloadBlockData } = useContext(ContextBlock);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { blockSave, setBlockSave } = useContext(ContextBlock);
  const {saveBlockAndCheckWorkflow} = useContext(ContextMissionDetailsTreatment);

  const { handleClickBlockCancel } = useContext(ContextBlock);
  const handleClickCancel = async () => {
    await controlFieldsErrorTruncate(controlFieldsMapping);
    handleClickBlockCancel(invoiceRef);
  }

  //init invoice data
  const {invoices, setInvoices} = useContext(ContextMissionDetails);
  const {invoicer, setInvoicer} = useContext(ContextMissionDetails);

  const [rowsInvoice, setRowsInvoice] = useState([]);
  const [rowsInvoiceView, setRowsInvoiceView] = useState([]);
  const [rowsInvoiceDeleted, setRowsInvoiceDeleted] = useState([]);

  const [invoiceTypes, setInvoiceTypes] = useState([]);

  //fields mapping
  const controlFieldsMapping = []; 
  controlFieldsMapping.push('invoiced_by');
  controlFieldsMapping.push('invoices_note');


  //useEffect
  useEffect(() => {
    setControlFieldsInvoice(controlFieldsMapping);
  }, []);

  //INIT general data
  useEffect(() => {
    //load data
    if (Object.keys(general).length > 0) {
      //dont update data if edition is in progress
      var blockInEdit  = blockEdit.findIndex(obj => (obj.blockRef === invoiceRef && obj.onEdit === false));
      if (blockInEdit >= 0) {
        //(RE)INIT detect change
        if (reloadData > oldReloadData) {
          setOldReloadData(reloadData);
        }

        //INIT invoiced by
        if (general.invoiced_by_company) setInvoicer(general.invoiced_by_company.id);
        else setInvoicer(0);
                
        // INIT invoice note
        if (general.invoices_note) setInvoicesNote(general.invoices_note);
        else setInvoicesNote('');
      }
    }
  }, [general, reloadData]);

  //INIT Invoices
  useEffect(() => {
    //load data form
    if (invoices) {
      if (invoices.length > 0) {
        //dont update data if edition is in progress
        var blockInEdit  = blockEdit.findIndex(obj => (obj.blockRef === invoiceRef && obj.onEdit === false));
        if (blockInEdit >= 0) {
          //(RE)INIT detect change
          if (reloadData > oldReloadData) {
            setOldReloadData(reloadData);
          }
          
          var invoicing = [];
          for (var i=0; i < invoices.length; i++) {
            var invoice_type = "";
            if (invoices[i].type) invoice_type = invoices[i].type.id;
            invoicing[i] = {
              id:invoices[i].id,
              id_receiver: (invoices[i].receiver ? invoices[i].receiver.id : 0),
              number: invoices[i].number,
              date: invoices[i].date,
              report_signature_date: invoices[i].report_signature_date,
              invoice_sent_date: invoices[i].invoice_sent_date,
              paid_on: invoices[i].paid_on,
              report_number: invoices[i].report_number,
              invoice_type: invoice_type
            };
          }
          setRowsInvoice(invoicing);
        }
      }
    }
  }, [invoices, reloadData]);

  useEffect(() => {
    //load data view
    if (rowsInvoice) {
      if (rowsInvoice.length > 0) {
        var rowsInvoiceApi = [];
        for(var i=0; i < rowsInvoice.length; i++) {
          rowsInvoiceApi[i] = {};
          rowsInvoiceApi[i].id = rowsInvoice[i].id;
          rowsInvoiceApi[i].number = rowsInvoice[i].number;
          rowsInvoiceApi[i].id_receiver = rowsInvoice[i].id_receiver;
          rowsInvoiceApi[i].report_number = rowsInvoice[i].report_number;
          rowsInvoiceApi[i].invoice_type = rowsInvoice[i].invoice_type;
          if(rowsInvoice[i].date) rowsInvoiceApi[i].date = momentDate(rowsInvoice[i].date).format("DD MMM YY");
          else rowsInvoiceApi[i].date = "";
          if(rowsInvoice[i].report_signature_date) rowsInvoiceApi[i].report_signature_date = momentDate(rowsInvoice[i].report_signature_date).format("DD MMM YY");
          else rowsInvoiceApi[i].report_signature_date = "";
          if(rowsInvoice[i].invoice_sent_date) rowsInvoiceApi[i].invoice_sent_date = momentDate(rowsInvoice[i].invoice_sent_date).format("DD MMM YY");
          else rowsInvoiceApi[i].invoice_sent_date = "";
          if(rowsInvoice[i].paid_on) rowsInvoiceApi[i].paid_on = momentDate(rowsInvoice[i].paid_on).format("DD MMM YY");
          else rowsInvoiceApi[i].paid_on = "";
        }
        setRowsInvoiceView(rowsInvoiceApi);
      }
    }
  }, [rowsInvoice]);

  //check block current edit
  useEffect(() => {
    //maj reload state
    if (reloadData == oldReloadData) {
      if (blockCurrentEdit?.reload === true) {
        setReloadData(reloadData + 1);
        setReloadBlockData(invoiceRef, false);
      }
    }
  }, [blockCurrentEdit]);

  //check block edit
  useEffect(() => {
    //load current block
    var blockCurrentIndex  = blockEdit.findIndex(obj => obj.blockRef === invoiceRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentEdit !== blockEdit[blockCurrentIndex]) {
        setBlockCurrentEdit(blockEdit[blockCurrentIndex]);
      }
    }
  }, [blockEdit]);

  //init block save
  useEffect(() => {
    saveBlockAndCheckWorkflow(invoiceRef, saveInvoices, checkWorkflowMissionDetailsInvoice);
  }, [blockSave, idMission]);

  //check block save
  useEffect(() => {
    //load current block
    var blockCurrentIndex  = blockSave.findIndex(obj => obj.blockRef === invoiceRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentSave !== blockSave[blockCurrentIndex]) {
        setBlockCurrentSave(blockSave[blockCurrentIndex]);
      }
    }
  }, [blockSave]);

  //check workflow error
  useEffect(() => {
    checkBlockOnWorkflowError(invoiceRef, controlFieldsMapping);
  }, [controlFieldsError, blockEdit]);


  //function interactive
  function createInvoicersHandler(data) {
    return {id:data.id, title:data.name.toUpperCase().trim(), alias:data.name, labelValue:data.name.toUpperCase().trim()};
  }
  
  function createInvoiceTypesHandler(data) {
    return { value: data.id, label: data.label.toUpperCase().trim() };
  }

  const processRowUpdateInvoice = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRowsInvoice(rowsInvoice.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const loadingInvoiceTypes = async () => {
    try {
      const config = {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        }
      };

      const response = await axios.get("invoice_type/list", config);

      var rowsFromApiInvoiceType = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApiInvoiceType[i] = {};
        rowsFromApiInvoiceType[i] = createInvoiceTypesHandler(response.data[i]);
      }
      rowsFromApiInvoiceType.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
      setInvoiceTypes(rowsFromApiInvoiceType);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  //function treatment
  const getDataSaveMissionDetailsInvoice = async () => {
    var data = new FormData();
    data.append('id_mission', Number(idMission));
    
    //INIT invoicer
    if (canWrite('invoiced_by')) {
      if (parseInt(invoicer) == 0) data.append('invoiced_by', null);
      else data.append('invoiced_by', parseInt(invoicer));
    }
    
    //INIT invoice note
    if (canWrite('invoices_note')) data.append('invoices_note', invoicesNote);

    return data;
  }

  const saveInvoices = async (justBlock = false) => {
    if (justBlock) {
      setLoading(true);
      setLoadingUpdate(false);
    }

    try {
      var saveInvoicePromises = [];
      //creating/updating invoices
      for (var i = 0; i < rowsInvoice.length; i++) {
        var data = new FormData();

        data.append('id_mission', Number(idMission));
        data.append('invoice_type', rowsInvoice[i].invoice_type);
        data.append('report_number', rowsInvoice[i].report_number);
        data.append('number', rowsInvoice[i].number);

        if (rowsInvoice[i].date) data.append('date', momentDate(rowsInvoice[i].date).format("YYYY-MM-DD"));
        else data.append('date', null);
        if (rowsInvoice[i].report_signature_date) data.append('report_signature_date', momentDate(rowsInvoice[i].report_signature_date).format("YYYY-MM-DD"));
        else data.append('report_signature_date', null);
        if (rowsInvoice[i].invoice_sent_date) data.append('invoice_sent_date', momentDate(rowsInvoice[i].invoice_sent_date).format("YYYY-MM-DD"));
        else data.append('invoice_sent_date', null);
        if (rowsInvoice[i].paid_on) data.append('paid_on', momentDate(rowsInvoice[i].paid_on).format("YYYY-MM-DD"));
        else data.append('paid_on', null);
        if (rowsInvoice[i].id_receiver) data.append('id_receiver', Number(rowsInvoice[i].id_receiver));
        else data.append('id_receiver', null);

        var methode = 'post';
        if (rowsInvoice[i].id.toString().length < 16) {
          methode = 'put';
          data.append('id_mission_invoice', rowsInvoice[i].id);
        }

        var jsonRequestDataSubInvoice = JSON.stringify(Object.fromEntries(data));
        var jsonRequestDataFinal = jsonRequestDataSubInvoice.replace(/"null"/g, 'null');

        var config = {
          url: MISSION_URL+'/invoice',
          method: methode,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: jsonRequestDataFinal
        };
        const response = await axios(config);
        saveInvoicePromises.push(response);

        if (rowsInvoice[i].id.toString().length > 16) {
          rowsInvoice[i].id = response.data.data.id;
          processRowUpdateInvoice(rowsInvoice[i]);
        }
      }

      //Deleting invoices
      for (var i = 0; i < rowsInvoiceDeleted.length; i++) {
        var data = new FormData();
        data.append('id_mission', Number(idMission));
        data.append('id_mission_invoice', Number(rowsInvoiceDeleted[i].id));

        var jsonRequestDataReceiver = JSON.stringify(Object.fromEntries(data));

        var config = {
          url: MISSION_URL+'/invoice',
          method: 'delete',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: jsonRequestDataReceiver
        };
        const response = await axios(config);
        saveInvoicePromises.push(response);
      }
      
      //save invoiced by and invoiced note
      var blockOnEdit = blockEdit.findIndex(obj => (obj.blockRef === generalRef && (
        obj.onEdit === false || (
          obj.onEdit === true && 
          blockSave.findIndex(obj2 => (obj2.blockRef === generalRef && obj2.onSave.start === false)) >= 0
        )
      )));
      if (blockOnEdit >= 0) {
        if (canWrite('invoiced_by') || canWrite('invoices_note')) {
          var dataMission = await getDataSaveMissionDetailsInvoice();
          //
          var data = { id_mission: Number(idMission) };
          //INIT invoicer
          if (canWrite('invoiced_by')) data.invoiced_by = invoicer ? Number(invoicer) : null;
          //INIT invoice note
          if (canWrite('invoices_note')) data.invoices_note = invoicesNote;
    
          var config = {
            method: 'put',
            url: MISSION_URL,
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
              'Content-Type': 'application/json'
            },
            data: data
          };    
          const response = await axios(config);
          saveInvoicePromises.push(response);

          //CHECK WORKFLOW DATA
          await checkWorkflowMissionDetailsInvoice(dataMission);
        }
      }

      //end treatment
      setRowsInvoiceDeleted([]);
      await Promise.all(saveInvoicePromises);

      if (justBlock) {
        setSnackBarMessage("Invoices saved.");
        setSnackBarType("success");
        setOpenSnackBar(true);
        //reload data api
        await getMissionDetail();
      } else {
        setDoneBlockSave(invoiceRef);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        if (justBlock) {
          var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
          setSnackBarMessage(errorMsg);
          setSnackBarType("error");
          setOpenSnackBar(true);
        } else {
          setErrorBlockSave(invoiceRef);
        }
      }
    } finally {
      if (justBlock) {
        setLoading(false);
        setLoadingUpdate(true);
      }
    }
  }

  const checkWorkflowMissionDetailsInvoice = async (dataMission = null) => {
    //CHECK WORKFLOW DATA
    await controlFieldsData(!!dataMission != false ? dataMission : await getDataSaveMissionDetailsInvoice());
  };


  return (
    <ContextMissionDetailsInvoice.Provider
      value=
      {{
        rowsInvoice, setRowsInvoice,
        rowsInvoiceView, setRowsInvoiceView,
        rowsInvoiceDeleted, setRowsInvoiceDeleted,
        
        invoiceTypes, setInvoiceTypes,
        loadingInvoiceTypes,

        createInvoicersHandler, processRowUpdateInvoice,

        handleClickCancel,
        controlFieldsMapping,
        saveInvoices
      }}
    >
      {children}
    </ContextMissionDetailsInvoice.Provider>
  );
};

export { MissionDetailsInvoiceProvider, ContextMissionDetailsInvoice };
