import {isInternalRole, isPortRole, isCustomerRole, isAdminRole, isDirectionRole} from './Roles';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

export function checkSecurity(navigate, page)
{
  if (!localStorage.getItem('isAuthenticated'))
  {
    if (page === 'MissionDocuments') {return;}
    navigate('/login', { replace: true });
  }
  else
  {
    if (isInternalRole(localStorage.getItem('role')))
    {
      if (page === 'DashboardList') {return;}
      else if (page === 'Dashboard') {return;}
      else if (page === 'MissionDetails') {return;}
      else if (page === 'CreateMission') { return; }
      else if (page === 'ShippingInstruction') { return; }
      else if (page === 'CreateShippingInstruction') { return; }
      else if (page === 'MailingLists') {return;}
      else if (page === 'EditMailingList') {return;}
      else if (page === 'Companies') {return;}
      else if (page === 'EditCompany') {return;}
      else if (page === 'MissionDocuments') {return;}
      else if (page === 'AnalyticsDashboard' || page === 'ExportBI')
      {
        if (isDirectionRole(localStorage.getItem('role')) || isAdminRole(localStorage.getItem('role'))) {return;}
      }
      navigate('/dashboard', { replace: true })
    }
    else if (isCustomerRole(localStorage.getItem('role')))
    {
      if (page === 'MissionDetails') {return;}
      navigate('/portal', { replace: true });
    }
    else if (isPortRole(localStorage.getItem('role')))
    {
      if (page === 'PortDashboard') {return;}
      else if (page === 'MissionDetails') {return;}
      else if (page === 'CreateMission') {return;}
      navigate('/dashboard-port', { replace: true });
    }
    else {
      localStorage.setItem('isAuthenticated', false);
      navigate('/login', { replace: true })
    }
    return;
  }
}
