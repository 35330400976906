import React, { useState, useEffect, forwardRef } from 'react';
import { useNavigate } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

import axios from 'api/axios';
import { isInternalRole } from 'components/config/Roles';
import TextFieldCustom from 'components/select/TextFieldCustom';
import BreadcrumbsCustom from './BreadcrumbsCustom';
import ProtisDetail from './MissionDetailsProtisDetail';
import ConfirmDialog from 'components/page/object/ConfirmDialog'
import 'styles/Receivers.css';
import 'styles/ProtisSection.css';
import PdfModal from './PdfModal';
import ProtisListItem from './MissionDetailsProtisListItem';
import {formatDate} from 'components/page/object/Utils'
import MissionDetailsProtisDocumentsForm from './MissionDetailsProtisDocumentsForm';

const ProtisForm = forwardRef((props, ref) => {
  
    const navigate = useNavigate();
    const [JLBInternalMode] = useState(isInternalRole(localStorage.getItem("role")));
    const [missionCanceled, setMissionCanceled] = useState(!!props.missionCanceled !== false ? props.missionCanceled : false);
    
    const [protisListElements, setProtisListElements] = useState([]);
    const [protisFullTree, setProtisFullTree] = useState({containers:[], photos:[], videos:[]});
    const [protisCurrentContainerId, setProtisCurrentContainerId] = useState(null);
    const [protisBreadcrumbsList, setProtisBreadcrumbsList] = useState([{id: null, label: 'Root folder',  active: true}]);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [openProtisDetail, setOpenProtisDetail] = useState(false);
    const [confirmDeleteModalElementInfo, setConfirmDeleteModalElementInfo] = useState("");
    const [detailElementInfo, setDetailElementInfo] = useState("");
    const [resetElementInfo, setResetElementInfo] = useState("");
    const [elementDeleted, setElementDeleted] = useState(false);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [openPdfModal, setOpenPdfModal] = useState(false);
    const [documentsList, setDocumentsList] = useState([]);
    const [openMovingForm, setOpenMovingForm] = useState(false);
    
    
    const GETMISSIONPROTISTREE_URL = "mission/container/tree";
    const MISSIONPROTISFOLDER_URL = "mission/container";
    const MISSIONPROTISPHOTO_URL = "mission/photo";
    const MISSIONPROTISVIDEO_URL = "mission/video";
    const MISSIONPROTISDOCUMENT_URL = "mission/document";
    const MISSIONPROTISDOCUMENTFILES_URL = "mission/document/file";

    // Load all list elements
    useEffect(()=>{
      if (props.idMission) {
        loadFullTree();
      }
    }, [props?.idMission]);

    useEffect(()=>{
      setMissionCanceled(props?.missionCanceled);
    }, [props?.missionCanceled]);

    //Reload current container when Arbo is changed
    useEffect(()=>{
      loadingContainerElementsList(false);
    }, [protisFullTree]);

    //Reload when current container is changed
    useEffect(()=>{
      loadingContainerElementsList(false);
    }, [protisCurrentContainerId]);

    // Delete Modal
    useEffect(() => {
      if (confirmDeleteModalElementInfo) {
        setOpenConfirmationModal(true);
      }
    }, [confirmDeleteModalElementInfo])
    
    useEffect(() => {
      if (!openConfirmationModal) {
        setConfirmDeleteModalElementInfo('');
      }
    }, [openConfirmationModal])
    
    // Protis Detail
    useEffect(() => {
      if (detailElementInfo) {
        setOpenProtisDetail(true);
      }
    }, [detailElementInfo])
    
    useEffect(() => {
      if (!openProtisDetail) {
        setDetailElementInfo('');
      }
    }, [openProtisDetail])
    
    useEffect(() => {
      if (openProtisDetail && resetElementInfo) {
        setDetailElementInfo(resetElementInfo);
        setResetElementInfo("");
      }
    }, [openProtisDetail, resetElementInfo])
    
    // If the detail modal is open and the list doesn't contain the deleted element, switch to the next element
    useEffect(() => {
      if (openProtisDetail && elementDeleted && detailElementInfo && protisListElements.length > 0) {
        let nextElement = null;

        // Detail modal case for document pages
        if (detailElementInfo?.isDocumentPage && documentsList?.length > 0) {
          // Updating document pages listPosition and orders
          const updatedDocumentPagesList = documentsList.map((document) => {
            if (document.id === detailElementInfo.document.id) {
              const updatedFiles = document?.files?.filter((file) => file.id !== detailElementInfo.id);
              const reorderedFiles = updatedFiles.map((file, index) => ({
                ...file,
                listPosition: index + 1,
                order: index + 1
              }));
          
              return {
                ...document,
                pagesCount: document.pagesCount - 1,
                files: reorderedFiles
              };
            }
            return document;
          });
          
          let currentDocument = updatedDocumentPagesList.find((document) => document.id == detailElementInfo.document.id);
          if (currentDocument) {  
            const nextListPosition = detailElementInfo.listPosition >= currentDocument.files.length ? 1 : detailElementInfo.listPosition + 1;
            nextElement = currentDocument.files.find( (element) => element.listPosition == nextListPosition ) || null;
            setDocumentsList(updatedDocumentPagesList);
          }
        // Detail modal case for documents
        } else if (detailElementInfo?.isDocument && documentsList?.length > 0) {
          let updatedDocumentsList = documentsList.filter((document) => document.id !== detailElementInfo.id);
          updatedDocumentsList = updatedDocumentsList.map((document, index) => ({
            ...document,
            listPosition: index + 1,
          }));
          const nextListPosition = detailElementInfo.listPosition >= updatedDocumentsList.length ? 1 : detailElementInfo.listPosition + 1;
          nextElement = updatedDocumentsList.find( (element) => element.listPosition == nextListPosition ) || null;
          setDocumentsList(updatedDocumentsList);
        // Other cases
        } else {
          const nextListPosition = detailElementInfo.listPosition >= protisListElements.length ? 1 : detailElementInfo.listPosition + 1;
          nextElement = protisListElements.find( (element) => element.listPosition == nextListPosition ) || null;
        }
        
        if (nextElement) {
          setDetailElementInfo(nextElement);
        } else {
          setOpenProtisDetail(false);
        }
        setElementDeleted(false);
      } else if (!openProtisDetail && !detailElementInfo && elementDeleted) {
        setElementDeleted(false);
      }
  
    }, [openProtisDetail, elementDeleted, detailElementInfo, protisListElements, documentsList])
  

    //Load all elements
    //return an array of 3 arrays : containers, photos, videos
    const loadFullTree = async () => {
      try {
        var config = {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
          params: {
            id_mission: props.idMission
          }
        };

        const response = await axios.get(GETMISSIONPROTISTREE_URL, config);
        setProtisFullTree(response.data?.data);

      } catch (err) {
        console.log(err);
        if (err.response?.status === 401) {
            navigate("/logout", { replace: true });
        } else {
          props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
          props.setSnackBarType("error");
          props.setOpenSnackBar(true);
        }
      }
    }

    const loadingContainerElementsList = async (reset) => {
      try {

        var elementsList = getCurrentContainerElementsList();

        refreshList(elementsList);
        
        if (reset) {
          setResetElementInfo(protisListElements[0]);
        }
      } catch (err) {
        console.log(err);
        if (err.response?.status === 401) {
            navigate("/logout", { replace: true });
        } else {
          props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
          props.setSnackBarType("error");
          props.setOpenSnackBar(true);
        }
      }
    }

    const getCurrentContainerElementsList = () => {
      var folders = protisFullTree.containers?.filter((row) => row.id_mission_container_master == protisCurrentContainerId);
      var photos = protisFullTree.photos?.filter((row) => row.id_mission_container == protisCurrentContainerId);
      var videos = protisFullTree.videos?.filter((row) => row.id_mission_container == protisCurrentContainerId);

      folders?.forEach((row) => {
        row.isFolder = true;
      });
      photos?.forEach((row) => {
        row.isPhoto = true;
      });
      videos?.forEach((row) => {
        row.isVideo = true;
      });
      
      return [...folders, ...photos, ...videos];
    }

    const refreshList = (elementsList) => {
      var sortedElements = [];
      var folders = elementsList.filter((row) => row.id_file == undefined);
      var medias = elementsList.filter((row) => row.id_file !== undefined);
      if (folders?.length > 0) {
        var sortedFolders = folders.sort(customSort);
        sortedElements.push(...sortedFolders);
      }
      if (medias?.length > 0) {
        var sortedMedias = medias.sort(customSort);
        sortedElements.push(...sortedMedias);
      }
      
      // Assign listPosition property to each element
      sortedElements.forEach((element, index) => {
        element.listPosition = index + 1;
      });

      setProtisListElements(sortedElements);
    }

    const refreshFullTree = (element, isDeletion, isMoving) => {
      var found = false;
      var fullTree = protisFullTree;
      if(element.isPhoto){
        if(!isDeletion && !isMoving){ // Add Photo
          fullTree.photos = fullTree.photos.map((photo) => {
            if(photo.id == element.id){
              found = true;
              return element;
            }
            return photo;
          });
          if(!found){
            fullTree.photos.push(element);
          }
        }else if (isDeletion && !isMoving){ // Delete Photo
          fullTree.photos = fullTree.photos.filter((photo) => photo.id !== element.id)
        }else if (!isDeletion && isMoving){ // Move Photo
          fullTree.photos = fullTree.photos.map((photo) => {
            if (photo.id == element.id) {
              return { ...photo, id_mission_container: element.id_mission_container };
            }
            return photo;
          });
        }
      }
      if(element.isVideo){
        if(!isDeletion && !isMoving){ // Add Video
          fullTree.videos = fullTree.videos.map((video) => {
            if(video.id == element.id){
              found = true;
              return element;
            }
            return video;
          });
          if(!found){
            fullTree.videos.push(element);
          }
        }else if (isDeletion && !isMoving){ // Delete Video
          fullTree.videos = fullTree.videos.filter((video) => video.id !== element.id)
        }else if (!isDeletion && isMoving){ // Move Video
          fullTree.videos = fullTree.videos.map((video) => {
            if (video.id == element.id) {
              return { ...video, id_mission_container: element.id_mission_container };
            }
            return video;
          });
        }
      }
      if(element.isFolder){
        if(!isDeletion && !isMoving){ // Add Folder
          fullTree.containers = fullTree.containers.map((container) => {
            if(container.id == element.id){
              found = true;
              return element;
            }
            return container;
          });
          if(!found){
            fullTree.containers.push(element);
          }
        }else if (isDeletion && !isMoving){ // Delete Folder
          fullTree.containers = fullTree.containers.filter((container) => container.id !== element.id)
        }else if (!isDeletion && isMoving){ // Move Folder
          fullTree.containers = fullTree.containers.map((container) => {
            if (container.id == element.id) {
              return { ...container, id_mission_container_master: element.id_mission_container_master };
            }
            return container;
          });
        }
      }
      setProtisFullTree((prevObject) => ({ ...prevObject, ...fullTree }));
    }
    
    const handleConfirmDeleteDialog = async (event, element) => {
      try {
        
        var data = new FormData();
        var url = MISSIONPROTISFOLDER_URL;
        data.append('id_mission', props.idMission);
        if (element?.isPhoto) {
          data.append('id_mission_photo', element.id);
          url = MISSIONPROTISPHOTO_URL;
        } else if(element?.isVideo){
          data.append('id_mission_video', element.id);
          url = MISSIONPROTISVIDEO_URL;
        } else if(element?.isDocument){
          data.append('id_mission_document', element.id);
          url = MISSIONPROTISDOCUMENT_URL;
        } else if(element?.isDocumentPage){
          data.append('id_mission_document', element.document.id);
          data.append('id_file', element.id);
          url = MISSIONPROTISDOCUMENTFILES_URL;
        } else {
          data.append('id_mission_container', element.id);
        }
  
        var jsonRequestData = JSON.stringify(Object.fromEntries(data));
  
        var config = {
          url: url,
          method: 'delete',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: jsonRequestData,
        };
        const response = await axios(config);
        if (response) {
          if (response.status == 200) {
            setElementDeleted(true);
            if (element?.isDocument && !openProtisDetail) {
              setDocumentsList(documentsList.filter((row) => row.id !== element.id));
            }
            if (!element?.isDocumentPage && !element.isDocument) {
              setProtisListElements(protisListElements.filter((row) => row.id !== element.id));
              refreshFullTree(element, true, false);
            }
            props.setSnackBarMessage((element?.isPhoto ? 'File' : element?.isVideo ? 'Video' : element?.isDocument ? 'Document' : element?.isDocumentPage ? 'Page' : 'Folder') + ' deleted.');
            props.setSnackBarType("success");
            props.setOpenSnackBar(true);
          }
        }
      }
      catch (err) {
        console.log(err);
        if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
        }
        else {
          props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
          props.setSnackBarType("error");
          props.setOpenSnackBar(true);
        }
      }
    };

    const loadDocumentDetails = async (idDocument, setLoadingFunc) => {
      setLoadingFunc(true);
      try {
        var config = {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
          params: {
            id_mission: props.idMission,
            id_mission_document: idDocument
          }
        };

        const response = await axios.get(MISSIONPROTISDOCUMENTFILES_URL, config);

        var document = response.data?.data;
        var documents = documentsList.map((doc, index) => {
          if(doc.id === document.id){
            document.isDocument = true;
            document.listPosition = index + 1;
            document.files.forEach((file, pageNumber) => {
              file.file_path = `data:${file.mime_type};base64,${file.file_path}`;
              file.isDocumentPage = 1;
              file.created_at = document.created_at;
              file.updated_at = document.updated_at;
              //need structured clone to avoid infinite data loop doc->files->doc->files...
              file.document = structuredClone(document);
              delete file.document.files;
              file.listPosition = pageNumber + 1;
            });
            return document;
          }
          return doc;
        });
        
        setDetailElementInfo(document);
        setDocumentsList(documents);
        setLoadingFunc(false);

      } catch (err) {
        console.log(err);
        if (err.response?.status === 401) {
            navigate("/logout", { replace: true });
        } else {
          props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
          props.setSnackBarType("error");
          props.setOpenSnackBar(true);
        }
      }
    }
    
    // Sort by updated_at or by created_at
    const customSort = (a, b) => {
      const aUpdatedAt = a.updated_at || a.created_at;
      const bUpdatedAt = b.updated_at || b.created_at;
    
      const dateA = new Date(aUpdatedAt).getTime();
      const dateB = new Date(bUpdatedAt).getTime(); 
    
      return dateB - dateA;
    };   
    
    const handlePhotosCommentChange = (event) => {
      props.setPhotosComment(event.target.value)
    };
    
    const handleBreadcrumbsClick = (element) => {
      const lastBreadcrumbElementIndex = protisBreadcrumbsList.findIndex(
        (breadcrumb) => breadcrumb.id === element.id
      );
      
      if (lastBreadcrumbElementIndex !== -1) {
        var updatedBreadcrumbsList = protisBreadcrumbsList.map((breadcrumb, index) => {
          if (index === lastBreadcrumbElementIndex) {
            return { ...breadcrumb, active: true };
          } else {
            return breadcrumb;
          }
        });
      }
        setProtisBreadcrumbsList(updatedBreadcrumbsList.slice(0, lastBreadcrumbElementIndex + 1));

        setProtisCurrentContainerId(element.id);
    };
    
    const handleGeneratePDFClick = () => {
      setOpenPdfModal(true);
    };
    
    // Open a folder or view a file
    const handleOpenFolderClick = (element) => {
      // Folder
      setProtisBreadcrumbsList((prevList) => [
        ...prevList.map((item) => ({ ...item, active: false })),
        { id: element?.id, label: element?.label, active: true },
      ]);
      setProtisCurrentContainerId(element.id ? element.id : null);
    };

    const setElementFirstChild = (currentFolder) => {
      if(currentFolder.containers?.length > 0){
        setDetailElementInfo(currentFolder.containers[0]);
      }else if(currentFolder.photos?.length > 0){
        setDetailElementInfo(currentFolder.photos[0]);
      }else if(currentFolder.videos?.length > 0){
        setDetailElementInfo(currentFolder.videos[0]);
      }else{
        var folder = protisFullTree.containers?.find((row) => row.id_mission_container_master == currentFolder.id);
        if(folder){
          setDetailElementInfo(folder);
        }else{
          var photo = protisFullTree.photos?.find((row) => row.id_mission_container == currentFolder.id);
          if(photo){
            setDetailElementInfo(photo);
          }else{
            var video = protisFullTree.videos?.find((row) => row.id_mission_container == currentFolder.id);
            if(video){
              setDetailElementInfo(video);
            }else{
              setDetailElementInfo([]);
            }
          }
        }
      }
    }
    
    // Move a folder or a file
    const handleMoveElementClick = (element) => {
      handleViewElementClick(element);
      setOpenMovingForm(true);
    };
    
    // Open a folder or view a file
    const handleViewElementClick = (element) => {
      setDetailElementInfo(element);
    };
    
    // Delete a folder or a file
    const handleDeleteElementClick = (element) => {
      setConfirmDeleteModalElementInfo(element);
    };
    
    const handleAddFolderClick = () => {
      setDetailElementInfo({
        isFolder:true, 
        id_mission_container:protisBreadcrumbsList[protisBreadcrumbsList.length-1].id
      });
    };
    
    const handleAddPhotoClick = () => {
      setDetailElementInfo({
        isPhoto:true, 
        id_mission_container :protisBreadcrumbsList[protisBreadcrumbsList.length-1].id
      });
    };
    
    const handleAddVideoClick = () => {
      setDetailElementInfo({
        isVideo:true, 
        id_mission_container:protisBreadcrumbsList[protisBreadcrumbsList.length-1].id
      });
    };

    return (
      <div id="block_protis" className={!props?.desactivateBlockMode ? (!missionCanceled ? 'contentDetail block_details' : 'contentDetail block_details disabled') : ''}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h4 style={{ margin: 0 }}>Protis</h4>
          <h6 style={{ margin: 0 }}>Dernière mise à jour à : {props.latestSyncAt ? formatDate(props.latestSyncAt, false) : '-/-/-'}</h6>
        </div>
        <div style={{marginTop: "2%"}}>
          <TextFieldCustom
            label="General comment"
            value={props.photosComment}
            onBlur={handlePhotosCommentChange}
            multiline
            rows={3}
            className="protisGeneralComment"
            variant="outlined"
            fullWidth

            read={true}
            write={!missionCanceled}
            controlFieldsStatus={true}
          />
        </div>
        <div style={{marginTop: "2%"}}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ margin: 0 }}>Photos | Videos - </h4>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginLeft: '10px' }}>
                <BreadcrumbsCustom
                  listElements={protisBreadcrumbsList}
                  handleClick={handleBreadcrumbsClick}
                  maxItems={4}
                />
              </div>
            </div>
            {JLBInternalMode &&
              <div style={{ margin: 0 }}>                      
                <LoadingButton
                  onClick={() => handleGeneratePDFClick()}
                  loading={loadingFiles}
                  variant="contained"
                >
                  Generate PDF
                </LoadingButton>
              </div>
            }
          </div>
            <List sx={{ m:1, width: '100%', bgcolor: 'background.paper', padding:'0px', borderRadius: '5px', border: '1px solid rgba(224, 224, 224)', height: loadingFiles ? '200px' : '400px', overflow: 'auto'}}>
              {!loadingFiles ?
                <>
                  {protisListElements.map((element, index) => (
                    <div key={`${element.id} + '-' + ${index}`}>
                      <ProtisListItem
                        {...props}
                        element={element}
                        protisFullTree={protisFullTree}
                        handleMoveElementClick={handleMoveElementClick}
                        handleViewElementClick={handleViewElementClick}
                        handleOpenFolderClick={handleOpenFolderClick}
                        handleDeleteElementClick={handleDeleteElementClick}
                        allowMoving={true}
                      />
                      {index !== protisListElements.length - 1 && <Divider style={{ backgroundColor: 'grey' }} />}
                    </div>
                  ))}
                </>
              :
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <CircularProgress />
                </Box>
              }
            </List>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ margin: '8px' }}>                      
                <LoadingButton
                  onClick={(e) =>{ handleAddFolderClick(); }}
                  loading={loadingFiles}
                  variant="contained"
                >
                  Add Folder
                </LoadingButton>
              </div>
              <div style={{ margin: '8px' }}>                      
                <LoadingButton
                  onClick={(e) => { handleAddPhotoClick(); }}
                  loading={loadingFiles}
                  variant="contained"
                >
                  Add Photo
                </LoadingButton>
              </div>
              <div style={{ margin: '8px' }}>                      
                <LoadingButton
                  onClick={(e) =>{ handleAddVideoClick(); }}
                  loading={loadingFiles}
                  variant="contained"
                >
                  Add Video
                </LoadingButton>
              </div>
            </div>
        </div>

        <MissionDetailsProtisDocumentsForm 
          idMission={props.idMission}
          latestSyncAt={props.latestSyncAt}
          missionCanceled={props.missionCanceled}
          setOpenSnackBar={props.setOpenSnackBar}
          setSnackBarType={props.setSnackBarType}
          setSnackBarMessage={props.setSnackBarMessage}
          setConfirmDeleteModalElementInfo={setConfirmDeleteModalElementInfo}
          setDetailElementInfo={setDetailElementInfo}
          setDocumentsList={setDocumentsList}
          documentsList={documentsList}
          loadDocumentDetails={loadDocumentDetails}
        />

        <ProtisDetail
          {...props}
          open={openProtisDetail}
          setOpen={setOpenProtisDetail}
          breadcrumbsList={protisBreadcrumbsList}
          breadcrumbsClick={handleBreadcrumbsClick}
          protisListElements={protisListElements}
          refreshList={refreshList}
          refreshFullTree={refreshFullTree}
          elementInfo={detailElementInfo}
          setElementInfo={setDetailElementInfo}
          handleDeleteDialog={handleDeleteElementClick}
          handleOpenFolderClick={handleOpenFolderClick}
          setProtisBreadcrumbsList={setProtisBreadcrumbsList}
          setProtisCurrentContainerId={setProtisCurrentContainerId}
          setElementFirstChild={setElementFirstChild}
          loadingFiles={loadingFiles}
          documentsList={documentsList}
          setDocumentsList={setDocumentsList}
          loadDocumentDetails={loadDocumentDetails}
          setReloadFileBlock={props.setReloadFileBlock}
          protisData={protisFullTree}
          openMovingForm={openMovingForm}
          setOpenMovingForm={setOpenMovingForm}
          allowMoving={true}
        />

        <ConfirmDialog
          title={"delete " + (confirmDeleteModalElementInfo.isPhoto || confirmDeleteModalElementInfo.isVideo ? 'file' : confirmDeleteModalElementInfo.isDocument ? 'document' : 'folder')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={handleConfirmDeleteDialog}
          params={confirmDeleteModalElementInfo}
        >
        {'Are you sure you want to delete this ' + (confirmDeleteModalElementInfo.isPhoto || confirmDeleteModalElementInfo.isVideo ? 'file' : confirmDeleteModalElementInfo.isDocument ? 'document' : confirmDeleteModalElementInfo.isDocumentPage ? 'page' : 'folder') + ' ?'}
        </ConfirmDialog>

        <PdfModal 
          {...props}
          open={openPdfModal}
          setOpen={setOpenPdfModal}
          breadcrumbsList={protisBreadcrumbsList}
          breadcrumbsClick={handleBreadcrumbsClick}
          loadFoldersChildren={loadingContainerElementsList}
          protisData={protisFullTree}
        />
      </div>
    );
});

export default ProtisForm;
