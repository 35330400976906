import React, { useState, useEffect, createContext, useContext } from "react";

import { useNavigate } from "react-router-dom";
import momentDate from 'moment';

import { ContextBlock } from "context/ContextBlock";

import axios from 'api/axios';


const ContextMissionDetails = createContext();

const MissionDetailsProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const { update } = useContext(ContextBlock);
  const { initBlock } = useContext(ContextBlock);
  const { actionSave } = useContext(ContextBlock);

  //API URL
  const GETMISSION = 'mission';

  //composant refs
  const { blockListRefs, elmtListRefs } = useContext(ContextBlock);
  //elmt refs
  const contentRef = elmtListRefs.contentRef.ref;
  const fileRef = elmtListRefs.fileRef.ref;
  //block refs
  const headerRef = blockListRefs.headerRef.ref;
  const generalRef = blockListRefs.generalRef.ref;
  const instructionRef = blockListRefs.instructionRef.ref;
  const receiverRef = blockListRefs.receiverRef.ref;
  const blRef = blockListRefs.blRef.ref;
  const noteRef = blockListRefs.noteRef.ref;
  const invoiceRef = blockListRefs.invoiceRef.ref;
  const subInvoiceRef = blockListRefs.subInvoiceRef.ref;
  const protisRef = blockListRefs.protisRef.ref;

  //generic
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { setSnackErrorMsg } = useContext(ContextBlock);

  //block config
  const { setActiveEdit } = useContext(ContextBlock);

  //mission data
  const { idElement } = useContext(ContextBlock);
  const [idMission, setIdMision] = useState(idElement);
  const [missionRef, setMissionRef] = useState('-');

  const [canceledAt, setCanceledAt] = useState(null);
  const [missionCanceled, setMissionCanceled] = useState(false);

  //mission header
  const [oldPinned, setOldPinned] = useState(0);
  const [pinned, setPinned] = useState(0);

  const [createdMissionDate, setCreatedMissionDate] = useState("");
  const [modifiedMissionDate, setModifiedMissionDate] = useState("");

  const [dateReminder, setDateReminder] = useState(momentDate().add(15, 'days'));
  const [dateReminderChanged, setDateReminderChanged] = useState(false);

  //mission header : status
  const [filterStatusArrayOld, setFilterStatusArrayOld] = useState([]);
  const [missionStatus, setMissionStatus] = useState(0);

  //mission data
  const [missionType, setMissionType] = useState(0);
  const [portId, setPortId] = useState(0);
  const [surveyHandler, setSurveyHandler] = useState(0);
  const [principalsId, setPrincipalsId] = useState(0);
  const [principalsAlias, setPrincipalsAlias] = useState("");

  const [internalComment, setInternalComment] = useState("");
  const [publicComment, setPublicComment] = useState("");
  const [photosComment, setPhotosComment] = useState("");
  const [latestSyncAt, setLatestSyncAt] = useState("");

  const [currentStage, setCurrentStage] = useState(0);

  const [invoicer, setInvoicer] = useState(0);
  const [invoicesNote, setInvoicesNote] = useState("");
  const [rowsReceiversInvoice, setRowsReceiversInvoice] = useState([]);

  const [dateBegining, setDateBegining] = useState(null);
  const [dateCompletion, setDateCompletion] = useState(null);
  
  //si data
  const [remainingTonnage, setRemainingTonnage] = useState(0);
  const [missionsVesselTonnage, setMissionsVesselTonnage] = useState([]);

  //composant data
  const [si, setSi] = useState([]);
  const [bls, setBLs] = useState([]);
  const [general, setGeneral] = useState({});
  const [receivers, setReceivers] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [subInvoices, setSubInvoices] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [notificationLog, setNotificationLog] = useState([]);
  const [internalNotification, setInternalNotification] = useState([]);
  const [nInternalNotifNoRead, setNRowsInternalNotifNoRead] = useState([]);


  //useEffect
  useEffect(() => {
    if (update == 1) {
      if (idMission === 0) {
        navigate('/createMission', { replace: true });
      } else if (!!actionSave != false) {
        if (actionSave == 1) {
          setSnackBarMessage("Mission saved.");
          setSnackBarType("success");
        } else {
          setSnackBarMessage("Problem saving mission.");
          setSnackBarType("error");
        }
        setOpenSnackBar(true);
      }
    }

    //reload variables if id set
    if (idMission > 0) {
      //Get details mission
      getMissionDetail();
    }
  }, []);

  useEffect(() => {
    //block is define
    if (initBlock && idMission <= 0 && update !== 1) {
      //set all block on edit
      setActiveEdit();
    }
  }, [initBlock]);

  useEffect(() => {
    if (canceledAt != null) {
      setMissionCanceled(true);
    }
  }, [canceledAt]);


  //function
  const getMissionDetail = async () => {
    setLoadingUpdate(false);
    try {
      var data = new FormData();
      var jsonRequestData = JSON.stringify(Object.fromEntries(data));

      var config = {
        url: GETMISSION+"?id_mission="+idMission,
        method: 'get',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: jsonRequestData
      };
      
      const reponseGet = await axios(config);

      //INIT general data
      var gnrlData = structuredClone(reponseGet.data);
      delete gnrlData.invoices;
      delete gnrlData.receivers;
      delete gnrlData.bills_of_lading;
      delete gnrlData.notifications_log;
      delete gnrlData.shipping_instruction;
      delete gnrlData.internal_notifications;
      delete gnrlData.subcontractor_invoices;
      delete gnrlData.mission_instructions;
      setGeneral(gnrlData);

      //INIT initValues
      //INIT shipping instruction
      setSi(reponseGet.data.shipping_instruction);

      //INIT mission instruction
      setInstructions(reponseGet.data.mission_instructions);

      //INIT receiver data
      setReceivers(reponseGet.data.receivers);

      //INIT bill of lading
      setBLs(reponseGet.data.bills_of_lading);

      //INIT invoice data
      setInvoices(reponseGet.data.invoices);

      //INIT sub invoice data
      setSubInvoices(reponseGet.data.subcontractor_invoices);

      //INIT notification log data
      setNotificationLog(reponseGet.data.notifications_log);

      //INIT notification log data
      setInternalNotification(reponseGet.data.internal_notifications);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingUpdate(true);
    }
  }

  const blockRedirect = (actionSave = 0) => {
    navigate('/missionDetails?id=' + idMission + '&actionSave=' + actionSave, { replace: (update !== 1) });
  }
  const blockGetData = async () => {
    await getMissionDetail();
  }


  return (
    <ContextMissionDetails.Provider
      value=
      {{
        //config context
        getMissionDetail,
        blockRedirect, blockGetData,

        //composant data ref
        contentRef, headerRef, generalRef, instructionRef, receiverRef, blRef, noteRef, invoiceRef, subInvoiceRef, fileRef, protisRef,

        //mission data
        idMission, setIdMision,
        missionRef, setMissionRef,

        canceledAt, setCanceledAt,
        missionCanceled, setMissionCanceled,

        dateBegining, setDateBegining,
        dateCompletion, setDateCompletion,

        missionType, setMissionType,
        portId, setPortId,
        surveyHandler, setSurveyHandler,
        principalsId, setPrincipalsId,
        principalsAlias, setPrincipalsAlias,
        
        //si data
        remainingTonnage, setRemainingTonnage,
        missionsVesselTonnage, setMissionsVesselTonnage,

        //header data
        oldPinned, setOldPinned,
        pinned, setPinned,

        createdMissionDate, setCreatedMissionDate,
        modifiedMissionDate, setModifiedMissionDate,

        filterStatusArrayOld, setFilterStatusArrayOld,
        missionStatus, setMissionStatus,
        dateReminderChanged, setDateReminderChanged,
        dateReminder, setDateReminder,

        //comment
        internalComment, setInternalComment,
        publicComment, setPublicComment,
        photosComment, setPhotosComment,
        latestSyncAt, setLatestSyncAt,
        currentStage, setCurrentStage,
        nInternalNotifNoRead, setNRowsInternalNotifNoRead,

        invoicesNote, setInvoicesNote,
        invoicer, setInvoicer,
        rowsReceiversInvoice, setRowsReceiversInvoice,

        //composant
        si, setSi,
        bls, setBLs,
        general, setGeneral,
        invoices, setInvoices,
        receivers, setReceivers,
        subInvoices, setSubInvoices,
        instructions, setInstructions,
        notificationLog, setNotificationLog,
        internalNotification, setInternalNotification
      }}
    >
      {children}
    </ContextMissionDetails.Provider>
  );
};

export { MissionDetailsProvider, ContextMissionDetails };
