import React, { useRef, useState, useEffect } from 'react';

import momentDate from 'moment';

import logo from 'imgs/logo.svg';
import 'bootstrap/dist/css/bootstrap.css';
import 'styles/MissionDocuments.css';
import axios from 'api/axios';
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar, IconButton } from '@mui/material';
import downloadIcon from 'imgs/download.svg';
import { saveAs } from 'file-saver';
import {formatBytes} from './object/Utils'
import ReCAPTCHA from "react-google-recaptcha"

const MissionDocuments = () => {

  //Init page (snackbar, navigate, captcha etc.)
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("");

  //Fetching query params
  const hash = queryParams.get('hash');
  const idMission = parseInt(queryParams.get('id_mission'));

  //Captcha
  const captchaRef = useRef(null)
  const [captchaOK, setCaptchaOK] = useState(false);

  //data
  const [link, setLink] = useState({});
  const [totalSize, setTotalSize] = useState(0);
  const [missionStatus, setMissionStatus] = useState({});
  const [linkDate, setLinkDate] = useState(momentDate());

  const archiveLimitDate = momentDate(process.env.REACT_APP_MISSION_DOC_LINK_ARCHIVED_ACTIVE);
  
  useEffect(() => {
    checkCaptcha();
    loadFiles();
  }, []);

  const checkCaptcha = async () => {
    const token = await captchaRef.current.executeAsync();
    if (token) {
      let valid_token = await verifyToken(token);
      if (valid_token.success) {
        setCaptchaOK(true);
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
      captchaRef.current.reset();
    } 
  }

  const verifyToken = async (token) => {
    try{
      let response = await axios.get("mission/documents/captcha?token="+token);
      return response.data;
    }catch(error){
      console.log("error ",error);
    }
  }

  const loadFiles = async () => {
    try {
      const response = await axios.get("mission/documents/list?id_mission=" + idMission + "&hash=" + hash);
      var total = 0;
      response.data.link?.files?.map((file) => {
        if(!isNaN(parseInt(file.file.size))){
          total += parseInt(file.file.size);
        }
      });
      setTotalSize(total);
      setLink(response.data.link);
      setMissionStatus(response.data.mission_status);
      setLinkDate(momentDate(response.data.link?.created_at));
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
    } 
  }

  //Download File
  const handleDownloadClick = (event) => {
    const { name } = event.target;
    if (captchaOK) {
      downloadFile(name);
    }
  }
  
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const downloadFile = async (idFile) => {
    try {
      var dlFile = null;
      link?.files?.map((file) =>{
        if (file.id == parseInt(idFile)) {
          dlFile = file;
        }
      });
      
      const response = await axios.get(
        "mission/documents",
        {
            params: { id_file: dlFile.id_file, hash:hash },
            responseType: 'arraybuffer',
            headers: {
              'content-type': dlFile.file.mime_type
            },
        },
      );
      // Let the user save the file.
      var blob = new Blob([response.data], { type: dlFile.file.mime_type });

      saveAs(blob, dlFile.file.file_name);

      setSnackBarMessage("File saved in your downloads folder.");
      setSnackBarType("success");
      setOpenSnackBar(true);
    }
    catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        console.log(err);
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  return (
    <div className="mission-documents">
      <div className="mission-documents-logo"><img src={logo} alt="JLB Expertises" /></div>
      
      <div className="mission-documents-container">
        <div className="mission-documents-recap">
          <div>
            <div className="gray-text">Files inside this transfer</div>
            <div className="light-gray-text">{link?.files?.length} Files, {formatBytes(totalSize, 1)}</div>
          </div>
        </div>
        <div className="mission-documents-files">
          {(missionStatus?.status?.value !== 10 || (missionStatus?.status?.value === 10 
            && (linkDate.isAfter(archiveLimitDate) || linkDate.isSame(archiveLimitDate)))) ? (
            link?.files?.map((file, index) => (

              <div key={index} className="mission-documents-cell">
                <div className="mission-documents-file">
                  <div style={{width:'85%', display:'inline-block'}}>
                    <div className="gray-text">{file.file.file_name}</div>
                    <div className="light-gray-text">{file.file_type.label}, {!isNaN(parseInt(file.file.size)) && formatBytes(parseInt(file.file.size), 1)}</div>
                  </div>
                  <div className="mission-documents-download" style={{width:'15%', display:'inline-block'}}>
                    <IconButton onClick={handleDownloadClick} className="mission-documents-download-button">
                        <img src={downloadIcon} name={file.id} />
                    </IconButton>
                  </div>
                </div>
              </div>
            ))
          ) : "This mission is archived, documents are no longer available here"}
        </div>
      </div>
      <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleCloseSnackbar} severity={snackBarType} sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        ref={captchaRef}
        size="invisible"
      />
    </div>
  );
}

export default MissionDocuments;
