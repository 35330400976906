import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import momentDate from 'moment';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";

import axios from 'api/axios';


const ContextMissionDetailsGeneral = createContext();

const MissionDetailsGeneralProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const { update } = useContext(ContextBlock);
  const { generalRef } = useContext(ContextMissionDetails);
  const { getMissionDetail } = useContext(ContextMissionDetails);

  //API URL
  const MISSION_URL = 'mission';

  //generic
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { setSnackErrorMsg } = useContext(ContextBlock);

  //workflow
  const { canWrite } = useContext(ContextMissionWorkflow);
  const { getWorkflow } = useContext(ContextMissionWorkflow);
  const { controlFieldsData } = useContext(ContextMissionWorkflow);
  const { checkBlockOnWorkflowError } = useContext(ContextMissionWorkflow);
  const {workflowFind, setWorkflowFind} = useContext(ContextMissionWorkflow);
  const {workflowLoaded, setWorkflowLoaded} = useContext(ContextMissionWorkflow);
  const {controlFieldsError, setControlFieldsError} = useContext(ContextMissionWorkflow);

  const {controlFieldsErrorTruncate} = useContext(ContextMissionWorkflow);
  const {controlFieldsGeneral, setControlFieldsGeneral} = useContext(ContextMissionWorkflow);

  //init id mission
  const {idMission, setIdMision} = useContext(ContextMissionDetails);

  //mission general state
  const {general, setGeneral} = useContext(ContextMissionDetails);

  const {missionRef, setMissionRef} = useContext(ContextMissionDetails);
  const {missionType, setMissionType} = useContext(ContextMissionDetails);
  const [missionTypeOld, setMissionTypeOld] = useState(0);
  const [missionIdTxt, setIdMisionTxt] = useState(pad(idMission));
  const [missionAlias, setMissionAlias] = useState("XXX");

  const {canceledAt, setCanceledAt} = useContext(ContextMissionDetails);

  const [port, setPort] = useState(null);
  const {portId, setPortId} = useContext(ContextMissionDetails);
  const [portAlias, setPortAlias] = useState("YYY");
  const [portCountryId, setPortCountryId] = useState(0);

  const {surveyHandler, setSurveyHandler} = useContext(ContextMissionDetails);
  const [surveyHandlerDefault, setSurveyHandlerDefault] = useState(0);

  const [localOffice, setLocalOffice] = useState(null);
  const [localOfficeId, setLocalOfficeId] = useState(0);

  const [localSurveyor, setLocalSurveyor] = useState(0);
  const [localSurveyorsList, setLocalSurveyorsList] = useState([]);
  const [localSurveyorEnable, setLocalSurveyorEnable] = useState(true);
  const [loadingLocalSurveys, setLoadingLocalSurveys] = useState(true);

  const [principals, setPrincipals] = useState(null);
  const {principalsId, setPrincipalsId} = useContext(ContextMissionDetails);
  const {principalsAlias, setPrincipalsAlias} = useContext(ContextMissionDetails);
  const [principalsRef, setPrincipalsRef] = useState("");

  const [trader, setTrader] = useState(null);
  const [traderId, setTraderId] = useState(0);

  const [insured, setInsured] = useState(null);
  const [insuredId, setInsuredId] = useState(0);
  const [insuredRef, setInsuredRef] = useState("");

  const [vessel, setVessel] = useState(null);
  const [vesselId, setVesselId] = useState(0);
  const [vesselArrival, setVesselArrival] = useState(null);
  const [vesselArrivalId, setVesselArrivalId] = useState(0);

  const [tonnage, setTonnage] = useState(0);

  const [warehouse, setWarehouse] = useState(0);
  const [warehouseList, setWarehouseList] = useState([]);
  const [warehouseEnable, setWarehouseEnable] = useState(true);
  const [warehouseAddButtonEnable, setWarehouseAddButtonEnable] = useState(true);
  const [loadingWarehouse, setLoadingWarehouse] = useState(true);

  const [warehouseKeeper, setWarehouseKeeper] = useState(null);
  const [warehouseKeeperId, setWarehpuseKeeperId] = useState(0);

  const [controlType, setControlType] = useState(0);

  const [dateETA, setDateETA] = useState(null);
  const [dateETB, setDateETB] = useState(null);
  const [dateCompletionOfDischarge, setDateCompletionOfDischarge] = useState(null);
  const [dateDOB, setDateDOB] = useState(null);
  const [dateDVD, setDateDVD] = useState(null);
  const [nextPortDischarge, setNextPortDischarge] = useState(null);
  const [dateInspection, setDateInspection] = useState(null);

  const {dateBegining, setDateBegining} = useContext(ContextMissionDetails);
  const {dateCompletion, setDateCompletion} = useContext(ContextMissionDetails);

  //header
  const { oldPinned, setOldPinned } = useContext(ContextMissionDetails);
  const { pinned, setPinned } = useContext(ContextMissionDetails);

  const { filterStatusArrayOld, setFilterStatusArrayOld } = useContext(ContextMissionDetails);
  const { dateReminderChanged, setDateReminderChanged } = useContext(ContextMissionDetails);
  const { dateReminder, setDateReminder } = useContext(ContextMissionDetails);

  //context
  const {latestSyncAt, setLatestSyncAt} = useContext(ContextMissionDetails);
  const {currentStage, setCurrentStage} = useContext(ContextMissionDetails);
  const {invoicesNote, setInvoicesNote} = useContext(ContextMissionDetails);
  const {invoicer, setInvoicer} = useContext(ContextMissionDetails);

  //treament
  const [reloadData, setReloadData] = useState(0);
  const [oldReloadData, setOldReloadData] = useState(0);
  const [blockCurrentEdit, setBlockCurrentEdit] = useState({});
  const [blockCurrentSave, setBlockCurrentSave] = useState({});
  
  const { setDoneBlockSave } = useContext(ContextBlock);
  const { setErrorBlockSave } = useContext(ContextBlock);
  const { setReloadBlockData } = useContext(ContextBlock);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { blockSave, setBlockSave } = useContext(ContextBlock);
  const {saveBlockAndCheckWorkflow} = useContext(ContextMissionDetailsTreatment);

  const { handleClickBlockCancel } = useContext(ContextBlock);
  const handleClickCancel = async (reload = true) => {
    await controlFieldsErrorTruncate(controlFieldsMapping);
    handleClickBlockCancel(generalRef, reload);
  }

  //fields mapping
  const controlFieldsMapping = []; 
  controlFieldsMapping.push('id_mission_type');
  controlFieldsMapping.push('id_port');
  controlFieldsMapping.push('id_survey_handler');
  controlFieldsMapping.push('id_local_office');
  controlFieldsMapping.push('id_local_surveyor');
  controlFieldsMapping.push('id_principal');
  controlFieldsMapping.push('principal_ref');
  controlFieldsMapping.push('id_trader');
  controlFieldsMapping.push('id_insured');
  controlFieldsMapping.push('insured_ref');
  controlFieldsMapping.push('id_vessel');
  controlFieldsMapping.push('vessel_tonnage');
  controlFieldsMapping.push('id_warehouse');
  controlFieldsMapping.push('id_control_type');
  controlFieldsMapping.push('estimated_time_arrival');
  controlFieldsMapping.push('estimated_time_berthing');
  controlFieldsMapping.push('date_of_berthing');
  controlFieldsMapping.push('completion_of_discharge');
  controlFieldsMapping.push('date_vessel_departure');
  controlFieldsMapping.push('next_port_of_discharge');
  controlFieldsMapping.push('id_current_stage');


  //useEffect
  useEffect(() => {
    setControlFieldsGeneral(controlFieldsMapping);

    if (!(update == 1)) {
      //init default survey
      setSurveyHandlerDefault(localStorage.getItem("userID"));
      setSurveyHandler(localStorage.getItem("userID"));
    }
  }, []);

  //INIT general data
  useEffect(() => {
    //load data
    if (Object.keys(general).length > 0) {
      //dont update data if edition is in progress
      var blockInEdit  = blockEdit.findIndex(obj => (obj.blockRef === generalRef && obj.onEdit === false));
      if (blockInEdit >= 0) {
        //(RE)INIT detect change
        if (reloadData > oldReloadData) {
          setOldReloadData(reloadData);
        }
        
        //INIT MISSION CANCELED
        setCanceledAt(general.canceled_at);

        //INIT MISSION TYPE
        setMissionType(general.mission_type.id);
        setMissionTypeOld(general.mission_type.id);
        setMissionAlias(general.mission_type.nomenclature);
        //load/unload workflow
        if (missionType > 0 && missionType != missionTypeOld && missionType != general.mission_type.id) {
          if (workflowLoaded) {
            setWorkflowFind(false);
            setWorkflowLoaded(false);
          } else {
            getWorkflow(general.mission_type.id);
          }
        }

        //INIT PORT
        setDatasPort(general.port.id, general.port.label, general.port.nomenclature, general.port);

        //INIT SURVEY HANDLER
        setSurveyHandler(general.survey_handler.id);

        //INIT Local Office
        //TODO label
        if (general.local_office) setDatasOffice(general.local_office.id, general.local_office.name, "");
        else setDatasOffice(0, '', '');

        //INIT LOCAL surveyor
        if (general.local_surveyor) setLocalSurveyor(general.local_surveyor.id);
        else setLocalSurveyor(0);

        //INIT PRINCIPALS
        setDatasPrincipals(general.principal.id, general.principal.name, general.principal.name);

        //INIT PRINCIPAL Ref
        if (general.principal_ref) setPrincipalsRef(general.principal_ref);
        else setPrincipalsRef('');

        //INIT Trader
        if (general.trader) setDatasTrader(general.trader.id, general.trader.name, "");
        else setDatasTrader(0, '', '');

        //INIT Insured
        if (general.insured) setDatasInsured(general.insured.id, general.insured.name, "");
        else setDatasTrader(0, '', '');

        //INIT Insured ref
        if (general.insured_ref) setInsuredRef(general.insured_ref);
        else setInsuredRef('');

        //INIT Vessel (BL)
        if (general.vessel) setDatasVessel(general.vessel.id, general.vessel.name, general.vessel.name);
        else setDatasVessel(0, '', '');
        
        //INIT Vessel (Arrival)
        if (general.vessel_arrival) setDatasVesselArrival(general.vessel_arrival.id, general.vessel_arrival.name);
        else setDatasVesselArrival(0, '');

        //INIT Tonnage
        if (general.vessel_tonnage) setTonnage(general.vessel_tonnage);
        else setTonnage(0);

        //INIT Warehouse
        if (general.warehouse) setWarehouse(general.warehouse.id);
        else setWarehouse(0);
        
        //INIT Warehouse Keeper
        if (general.warehouse_keeper) setDatasWarehouseKeeper(general.warehouse_keeper.id, general.warehouse_keeper.name);
        else setDatasWarehouseKeeper(0, '');

        //INIT Control type
        if (general.control_type) setControlType(general.control_type.id);
        else setControlType(0);

        //INIT ETA
        if (general.estimated_time_arrival) setDateETA(general.estimated_time_arrival);
        else setDateETA(null);

        //INIT ETB
        if (general.estimated_time_berthing) setDateETB(general.estimated_time_berthing);
        else setDateETB(null);

        //INIT COMPLETION OF DISCHARGE
        if (general.completion_of_discharge) setDateCompletionOfDischarge(general.completion_of_discharge);
        else setDateCompletionOfDischarge(null);

        //INIT DOB
        if (general.date_of_berthing) setDateDOB(general.date_of_berthing);
        else setDateDOB(null);

        //INIT DVD
        if (general.date_vessel_departure) setDateDVD(general.date_vessel_departure);
        else setDateDVD(null);

        //INIT NextPortOfDischarge
        if (general.next_port_of_discharge) setNextPortDischarge(general.next_port_of_discharge);
        else setNextPortDischarge(null);

        //INIT operations_begining_date
        if (general.operations_begining_date) setDateBegining(general.operations_begining_date);
        else setDateBegining(null);

        //INIT operations_completion_date
        if (general.operations_completion_date) setDateCompletion(general.operations_completion_date);
        else setDateCompletion(null);

        //INIT Current stage
        if (general.current_stage) setCurrentStage(general.current_stage.id);
        else setCurrentStage(0);

        //INIT Inspection date
        if (general.inspection_date) setDateInspection(general.inspection_date);
        else setDateInspection(null);
        
        //INIT latest_sync_at
        if (general.latest_sync_at) setLatestSyncAt(general.latest_sync_at);
        else setLatestSyncAt(null);
      }
    }
  }, [general, reloadData]);

  //check block current edit
  useEffect(() => {
    //maj reload state
    if (reloadData == oldReloadData) {
      if (blockCurrentEdit?.reload === true) {
        setReloadData(reloadData + 1);
        setReloadBlockData(generalRef, false);
      }
    }
  }, [blockCurrentEdit]);

  //check block edit
  useEffect(() => {
    //load current block
    var blockCurrentIndex  = blockEdit.findIndex(obj => obj.blockRef === generalRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentEdit !== blockEdit[blockCurrentIndex]) {
        setBlockCurrentEdit(blockEdit[blockCurrentIndex]);
      }
    }
  }, [blockEdit]);

  //init block save
  useEffect(() => {
    saveBlockAndCheckWorkflow(generalRef, saveMissionDetailsGeneral, checkWorkflowMissionDetailsGeneral);
  }, [blockSave]);

  //check block save
  useEffect(() => {
    //load current block
    var blockCurrentIndex  = blockSave.findIndex(obj => obj.blockRef === generalRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentSave !== blockSave[blockCurrentIndex]) {
        setBlockCurrentSave(blockSave[blockCurrentIndex]);
      }
    }
  }, [blockSave]);

  //check workflow error
  useEffect(() => {
    checkBlockOnWorkflowError(generalRef, controlFieldsMapping);
  }, [controlFieldsError, blockEdit]);

  useEffect(() => {
    if (idMission === 0) {
      setMissionRef(missionAlias+'-'+portAlias+'-'+missionIdTxt);
    }
  }, [missionAlias, portAlias]);


  //function interactive
  function pad(num) {
    var s = "000000" + num;
    return s.substr(s.length-6);
  }

  function createLocalSurveyorHandler(data) {
    return {id:data.id, label: data.firstname+ ' '+data.lastname, alias:""};
  }

  function createSurveyHandlerHandler(data) {
    return { id: data.id, title: data.firstname + " " + data.lastname, alias: "", labelValue: data.firstname + " " + data.lastname };
  }

  function createWarehouseHandler(data) {
    return {id:data.id, label: data.label?.toUpperCase().trim(), labelValue: data.label?.toUpperCase().trim() };
  }

  function createMissionTypeHandler(data) {
    return { id: data.id, title: data.label, alias: data.nomenclature, labelValue: data.label };
  }

  function createControlTypeHandler(data) {
    return { id: data.id, title: data.label?.toUpperCase().trim(), alias: data.label, labelValue: data.label?.toUpperCase().trim() };
  }

  function createCurrentStageHandler(data) {
    return { id: data.id, title: data.label.toUpperCase().trim(), alias: data.label, labelValue: data.label.toUpperCase().trim() };
  }

  function setDatasPort(id, label, alias, data) {
    setPortId(id);
    setPort(label);
    setPortAlias(alias);
    refreshWarehouse(id);
    if (typeof data != "undefined") setPortCountryId(data.id_country);
  }
  
  function setDatasOffice(id, label, alias) {
    setLocalOfficeId(id)
    setLocalOffice(label);
    refreshLocalSurveyor(id);
  }

  function setDatasPrincipals(id, label, alias) {
    setPrincipalsId(id)
    setPrincipals(label?.toUpperCase().trim());
    setPrincipalsAlias(alias);
  }

  function setDatasTrader(id, label, alias) {
    setTraderId(id)
    setTrader(label?.toUpperCase().trim());
  }

  function setDatasVessel(id, label, alias) {
    setVesselId(id)
    setVessel(label);
  }

  function setDatasVesselArrival(id, label) {
    setVesselArrivalId(id)
    setVesselArrival(label);
  }

  function setDatasWarehouseKeeper(id, label) {
    setWarehpuseKeeperId(id)
    setWarehouseKeeper(label);
  }

  function setDatasInsured(id, label, alias) {
    setInsuredId(id)
    setInsured(label?.toUpperCase().trim());
  }

  const refreshLocalSurveyor = async (idLocalOffice) => {
    try {
      setLoadingLocalSurveys(false);
      setLocalSurveyorEnable(true);
      setLocalSurveyor(0);
      const config = {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        }
      };

      const response = await axios.get("company/list?company_role=Local Office", config);

      var rowsFromApi = [];
      for(var i=0; i < response.data.length; i++) {
        if (response.data[i].id === idLocalOffice) {
          if (response.data[i].contacts) {
            for(var j=0; j < response.data[i].contacts.length; j++) {
              rowsFromApi[j] = createLocalSurveyorHandler(response.data[i].contacts[j]);
            }
          }
        }
      }

      if (rowsFromApi.length > 0) setLocalSurveyorEnable(false);
      rowsFromApi.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
      setLocalSurveyorsList(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingLocalSurveys(true);
    }
  }

  const refreshWarehouse = async (idPort) => {
    try {
      if (Boolean(idPort)) setWarehouseAddButtonEnable(false);
      else setWarehouseAddButtonEnable(true);
      setLoadingWarehouse(false);
      setWarehouseEnable(true);
      setWarehouse(0);
      const config = {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        }
      };
      const response = await axios.get("warehouse/list?id_port="+idPort, config);
      var rowsFromApi = [];
      for (var i=0; i < response.data.length; i++) {
        rowsFromApi[i] = createWarehouseHandler(response.data[i]);
      }
      if (rowsFromApi.length > 0) setWarehouseEnable(false);
      rowsFromApi.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
      setWarehouseList(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
        setSnackBarMessage(errorMsg);
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingWarehouse(true);
    }
  }

  //function treatment
  const getDataSaveMissionDetailsGeneral = async () => {
    var data = new FormData();
    if (update == 1) data.append('id_mission', parseInt(idMission));

    //OBLIGATOIRE
    data.append('id_status', parseInt(filterStatusArrayOld[0]));

    if (canWrite('id_mission_type'))
      data.append('id_mission_type', parseInt(missionType));
    if (canWrite('id_port'))
      data.append('id_port', parseInt(portId));
    if (canWrite('id_principal'))
      data.append('id_principal', parseInt(principalsId));
    if (canWrite('id_survey_handler'))
      data.append('id_survey_handler', parseInt(surveyHandler));
    /////

    if (canWrite('principal_ref'))
      data.append('principal_ref', String(principalsRef));
    if (canWrite('insured_ref'))
      data.append('insured_ref', String(insuredRef));
    if (canWrite('id_local_office')) {
      if (parseInt(localOfficeId) == 0) data.append('id_local_office', null);
      else data.append('id_local_office', parseInt(localOfficeId));
    }
    if (canWrite('id_local_surveyor')) {
      if (parseInt(localSurveyor) == 0) data.append('id_local_surveyor', null);
      else data.append('id_local_surveyor', parseInt(localSurveyor));
    }
    if (canWrite('id_vessel')) {
      if (parseInt(vesselId) == 0) data.append('id_vessel', null);
      else data.append('id_vessel', parseInt(vesselId));
    }
    if (canWrite('id_vessel_arrival')) {
      if (parseInt(vesselArrivalId) == 0) data.append('id_vessel_arrival', null);
      else data.append('id_vessel_arrival', parseInt(vesselArrivalId));
    }
    if (canWrite('vessel_tonnage'))
      data.append('vessel_tonnage', Number(tonnage));
    if (canWrite('id_insured')) {
      if (parseInt(insuredId) == 0) data.append('id_insured', null);
      else data.append('id_insured', parseInt(insuredId));
    }
    if (canWrite('id_trader')) {
      if (parseInt(traderId) == 0) data.append('id_trader', null);
      else data.append('id_trader', parseInt(traderId));
    }
    if (canWrite('id_control_type')) {
      if ((parseInt(controlType) == 0) || (parseInt(controlType) == NaN)) data.append('id_control_type', null);
      else data.append('id_control_type', parseInt(controlType));
    }

    //INIT date reminder
    if (momentDate().diff(dateReminder, 'days') > 0) {
      data.append('reminder_date', null);
    } else {
      if (dateReminderChanged) {
        data.append('reminder_date', momentDate(dateReminder).format("YYYY-MM-DD"));
      }
    }

    //INIT ETA / ETB / DOB / DVD / NextPortOfDischarge
    if (canWrite('estimated_time_arrival')) {
      if (dateETA) data.append('estimated_time_arrival', momentDate(dateETA).format("YYYY-MM-DD"));
      else data.append('estimated_time_arrival', null);
    }
    if (canWrite('estimated_time_berthing')) {
      if (dateETB) data.append('estimated_time_berthing', momentDate(dateETB).format("YYYY-MM-DD"));
      else data.append('estimated_time_berthing', null);
    }
    if (canWrite('completion_of_discharge')) {
      if (dateCompletionOfDischarge) data.append('completion_of_discharge', momentDate(dateCompletionOfDischarge).format("YYYY-MM-DD"));
      else data.append('completion_of_discharge', null);
    }
    if (canWrite('date_of_berthing')) {
      if (dateDOB) data.append('date_of_berthing', momentDate(dateDOB).format("YYYY-MM-DD"));
      else data.append('date_of_berthing', null);
    }
    if (canWrite('date_vessel_departure')) {
      if (dateDVD) data.append('date_vessel_departure', momentDate(dateDVD).format("YYYY-MM-DD"));
      else data.append('date_vessel_departure', null);
    }
    if (canWrite('next_port_of_discharge'))
      data.append('next_port_of_discharge', String(nextPortDischarge));

    if (canWrite('id_current_stage')) {
      if ((parseInt(currentStage) == 0) || (parseInt(currentStage) == NaN)) data.append('id_current_stage', null);
      else data.append('id_current_stage', parseInt(currentStage));
    }
    if (canWrite('inspection_date')) {
      if (dateInspection) data.append('inspection_date', momentDate(dateInspection).format("YYYY-MM-DD"));
      else data.append('inspection_date', null);
    }

    //INIT operations_begining_date
    if (canWrite('operations_begining_date')) {
      if (dateBegining) data.append('operations_begining_date', momentDate(dateBegining).format("YYYY-MM-DD"));
      else data.append('operations_begining_date', null);
    }
    if (canWrite('operations_completion_date')) {
      if (dateCompletion) data.append('operations_completion_date', momentDate(dateCompletion).format("YYYY-MM-DD"));
      else data.append('operations_completion_date', null);
    }

    //Report & Invoice section
    if (canWrite('invoiced_by')) {
      if (parseInt(invoicer) == 0) data.append('invoiced_by', null);
      else data.append('invoiced_by', parseInt(invoicer));
    }
    if (canWrite('invoices_note'))
      data.append('invoices_note', invoicesNote);

    if (canWrite('id_warehouse')) {
      if (parseInt(warehouse) == 0) data.append('id_warehouse', null);
      else data.append('id_warehouse', parseInt(warehouse));
    }
    if (canWrite('id_warehouse_keeper')) {
      if (parseInt(warehouseKeeperId) == 0) data.append('id_warehouse_keeper', null);
      else data.append('id_warehouse_keeper', parseInt(warehouseKeeperId));
    }

    return data;
  }

  const saveMissionDetailsGeneral = async (justBlock = false) => {
    if (justBlock) {
      setLoading(true);
      setLoadingUpdate(false);
    }

    try {
      var data = await getDataSaveMissionDetailsGeneral();
      var dataMission = data;

      //API DATA SENT
      var jsonRequestData = JSON.stringify(Object.fromEntries(data));
      var jsonRequestDataFinal = jsonRequestData.replace(/"null"/g, 'null');    //PATCH ERREUR NULL

      var config = {
        method: 'post',
        url: MISSION_URL,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: jsonRequestDataFinal
      };

      if (update == 1) {
        config.method = 'put';
      }

      const response = await axios(config);

      //ID registration
      if (!(update == 1)) {
        setIdMision(response.data.data.id);
      }

      //CHECK WORKFLOW DATA
      await checkWorkflowMissionDetailsGeneral(dataMission);

      if (justBlock) {
        setSnackBarMessage("General Informations saved.");
        setSnackBarType("success");
        setOpenSnackBar(true);
        //reload data api
        await getMissionDetail();
      } else {
        setDoneBlockSave(generalRef);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        if (justBlock) {
          var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
          setSnackBarMessage(errorMsg);
          setSnackBarType("error");
          setOpenSnackBar(true);
        } else {
          setErrorBlockSave(generalRef);
        }
      }
    } finally {
      if (justBlock) {
        setLoading(false);
        setLoadingUpdate(true);
      }
    }
  };

  const checkWorkflowMissionDetailsGeneral = async (dataMission = null) => {
    //CHECK WORKFLOW DATA
    await controlFieldsData(!!dataMission != false ? dataMission : await getDataSaveMissionDetailsGeneral());
  };


  return (
    <ContextMissionDetailsGeneral.Provider
      value=
      {{
        idMission, setIdMision,

        missionType, setMissionType,
        missionAlias, setMissionAlias,
        createMissionTypeHandler,

        port, setPort,
        portId, setPortId,
        portAlias, setPortAlias,
        portCountryId, setPortCountryId,
        setDatasPort,

        surveyHandler, setSurveyHandler,
        surveyHandlerDefault, setSurveyHandlerDefault,
        createSurveyHandlerHandler,

        localOffice, setLocalOffice,
        localOfficeId, setLocalOfficeId,
        setDatasOffice,

        localSurveyor, setLocalSurveyor,
        localSurveyorsList, setLocalSurveyorsList,
        localSurveyorEnable, setLocalSurveyorEnable,
        loadingLocalSurveys, setLoadingLocalSurveys,
        refreshLocalSurveyor,
        createLocalSurveyorHandler,

        principals, setPrincipals,
        principalsId, setPrincipalsId,
        principalsAlias, setPrincipalsAlias,
        principalsRef, setPrincipalsRef,
        setDatasPrincipals,

        trader, setTrader,
        traderId, setTraderId,
        setDatasTrader,

        insured, setInsured,
        insuredId, setInsuredId,
        insuredRef, setInsuredRef,
        setDatasInsured,

        vessel, setVessel,
        vesselId, setVesselId,
        setDatasVessel,
        vesselArrival, setVesselArrival,
        vesselArrivalId, setVesselArrivalId,
        setDatasVesselArrival,

        tonnage, setTonnage,

        warehouse, setWarehouse,
        warehouseList, setWarehouseList,
        warehouseEnable, setWarehouseEnable,
        warehouseAddButtonEnable, setWarehouseAddButtonEnable,
        loadingWarehouse, setLoadingWarehouse,
        refreshWarehouse,
        createWarehouseHandler,

        warehouseKeeper, setWarehouseKeeper,
        warehouseKeeperId, setWarehpuseKeeperId,
        setDatasWarehouseKeeper,

        controlType, setControlType,
        createControlTypeHandler,

        dateETA, setDateETA,
        dateETB, setDateETB,
        dateCompletionOfDischarge, setDateCompletionOfDischarge,
        dateDOB, setDateDOB,
        dateDVD, setDateDVD,
        createCurrentStageHandler,
        nextPortDischarge, setNextPortDischarge,

        dateBegining, setDateBegining,
        dateCompletion, setDateCompletion,
        dateInspection, setDateInspection,

        handleClickCancel,
        controlFieldsMapping,
        saveMissionDetailsGeneral
      }}
    >
      {children}
    </ContextMissionDetailsGeneral.Provider>
  );
};

export { MissionDetailsGeneralProvider, ContextMissionDetailsGeneral };
