import React, { useEffect, createContext, useContext } from "react";

import { ContextMissionDetails } from "context/ContextMissionDetails";


const ContextMissionDetailsNotification = createContext();

const MissionDetailsNotificationProvider = ({ children }) => {

  //init notif log data
  const {notificationLog, setNotificationLog} = useContext(ContextMissionDetails);

  const [rowsNotificationLog, setRowsNotificationLog] = React.useState([]);

  //init inter notif data
  const {internalNotification, setInternalNotification} = useContext(ContextMissionDetails);

  const [rowsInternalNotification, setRowsInternalNotification] = React.useState([]);
  const {nInternalNotifNoRead, setNRowsInternalNotifNoRead} = useContext(ContextMissionDetails);


  //INIT Notification Log
  useEffect(() => {
    //load data
    if (notificationLog) {
      if (notificationLog.length > 0) {
        var rowsNotificationLogApi = [];
        for(var i=0; i < notificationLog.length; i++) {
          //user info
          var userDataLoad = '';
          if(notificationLog[i].user){
            userDataLoad = notificationLog[i].user.firstname + ' ' + notificationLog[i].user.lastname;
          }
          //status info
          var statusDataLoad = '';
          if(notificationLog[i].status){
            statusDataLoad = notificationLog[i].status.label;
          }

          rowsNotificationLogApi[i]         = {};
          rowsNotificationLogApi[i].id      = notificationLog[i].id;
          rowsNotificationLogApi[i].type    = notificationLog[i].type.toUpperCase();
          rowsNotificationLogApi[i].label   = notificationLog[i].label;
          rowsNotificationLogApi[i].content = notificationLog[i].content;
          rowsNotificationLogApi[i].sender  = userDataLoad;
          rowsNotificationLogApi[i].status  = statusDataLoad;
          rowsNotificationLogApi[i].date    = notificationLog[i].send_date;

          //recipient
          switch(notificationLog[i].type){
            case 'sms':
            case 'whatsapp':
              rowsNotificationLogApi[i].to = notificationLog[i].to_phone_number;
              break;

            default:
              rowsNotificationLogApi[i].to = notificationLog[i].to_email;
              break;
          }
        }
        setRowsNotificationLog(rowsNotificationLogApi);
      }
    }
  }, [notificationLog]);

  //INIT Internal Notification
  useEffect(() => {
    //load data
    if (internalNotification) {
      if (internalNotification.length > 0) {
        var nRowsInternalNotifNoRead = 0;
        var rowsInternalNotificationApi = [];
        for(var i=0; i < internalNotification.length; i++) {
          rowsInternalNotificationApi[i]         = {};
          rowsInternalNotificationApi[i].id      = internalNotification[i].id;
          rowsInternalNotificationApi[i].marked_as_read = internalNotification[i].marked_as_read;
          rowsInternalNotificationApi[i].text    = internalNotification[i].notification.text;
          rowsInternalNotificationApi[i].date    = internalNotification[i].date;
          rowsInternalNotificationApi[i].read_on = internalNotification[i].read_on;
          if(rowsInternalNotificationApi[i].marked_as_read != "1") nRowsInternalNotifNoRead++;
        }
        setRowsInternalNotification(rowsInternalNotificationApi);
        setNRowsInternalNotifNoRead(nRowsInternalNotifNoRead);
      }
    }
  }, [internalNotification]);


  return (
    <ContextMissionDetailsNotification.Provider
      value=
      {{
        //notif log data
        rowsNotificationLog, setRowsNotificationLog,
      
        //inter notif data
        rowsInternalNotification, setRowsInternalNotification,
        nInternalNotifNoRead, setNRowsInternalNotifNoRead
      }}
    >
      {children}
    </ContextMissionDetailsNotification.Provider>
  );
};

export { MissionDetailsNotificationProvider, ContextMissionDetailsNotification };
