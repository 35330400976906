import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import momentDate from 'moment';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";

import axios from 'api/axios';


const ContextMissionDetailsInstruction = createContext();

const MissionDetailsInstructionProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const { instructionRef } = useContext(ContextMissionDetails);
  const { getMissionDetail } = useContext(ContextMissionDetails);

  //API URL
  const MISSION_INSTRUCTION_URL = 'mission/instruction';

  //generic
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { setSnackErrorMsg } = useContext(ContextBlock);

  //init id mission
  const { idMission, setIdMission } = useContext(ContextMissionDetails);

  //instruction state
  const { instructions, setInstructions } = useContext(ContextMissionDetails);

  const [rowsInstruction, setRowsInstruction] = useState([]);
  const [rowsInstructionDeleted, setRowsInstructionDeleted] = useState([]);

  //treament
  const [reloadData, setReloadData] = useState(0);
  const [oldReloadData, setOldReloadData] = useState(0);
  const [blockCurrentEdit, setBlockCurrentEdit] = useState({});
  const [blockCurrentSave, setBlockCurrentSave] = useState({});
  
  const { setDoneBlockSave } = useContext(ContextBlock);
  const { setErrorBlockSave } = useContext(ContextBlock);
  const { setReloadBlockData } = useContext(ContextBlock);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { blockSave, setBlockSave } = useContext(ContextBlock);
  const { saveBlockAndCheckWorkflow } = useContext(ContextMissionDetailsTreatment);

  const { handleClickBlockCancel } = useContext(ContextBlock);
  const handleClickCancel = async (reload = true) => {
    handleClickBlockCancel(instructionRef, reload);
  }


  //INIT mission data
  useEffect(() => {
    //load data
    if (instructions) {
      if (instructions.length > 0) {
        //dont update data if edition is in progress
        var blockInEdit = blockEdit.findIndex(obj => (obj.blockRef === instructionRef && obj.onEdit === false));
        if (blockInEdit >= 0) {
          //(RE)INIT detect change
          if (reloadData > oldReloadData) {
            setOldReloadData(reloadData);
          }

          //INIT Mission Instruction
          var rowsFromApi = [];
          for (var i = 0; i < instructions.length; i++) {
            const instruction = instructions[i];
            rowsFromApi[i] = {
              id: instruction.id,
              id_mission: instruction.id_mission,
              id_si_instruction: instruction.si_instruction?.id,
              id_instruction_type: instruction.instruction_type.id,
              id_place_of_operations: instruction.place_of_operations?.id,
              begining_date: instruction.begining_date,
              completion_date: instruction.completion_date,
              dhl: instruction.dhl,
              remark: instruction.remark
            };
          }
          setRowsInstruction(rowsFromApi);
        }
      }
      else setRowsInstruction([]);
    }
  }, [instructions, reloadData]);

  //check block current edit
  useEffect(() => {
    //maj reload state
    if (reloadData == oldReloadData) {
      if (blockCurrentEdit?.reload === true) {
        setReloadData(reloadData + 1);
        setReloadBlockData(instructionRef, false);
      }
    }
  }, [blockCurrentEdit]);

  //check block edit
  useEffect(() => {
    //load current block
    var blockCurrentIndex = blockEdit.findIndex(obj => obj.blockRef === instructionRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentEdit !== blockEdit[blockCurrentIndex]) {
        setBlockCurrentEdit(blockEdit[blockCurrentIndex]);
      }
    }
  }, [blockEdit]);

  //init block save
  useEffect(() => {
    saveBlockAndCheckWorkflow(instructionRef, saveMissionDetailsInstruction);
  }, [blockSave, idMission]);

  //check block save
  useEffect(() => {
    //load current block
    var blockCurrentIndex = blockSave.findIndex(obj => obj.blockRef === instructionRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentSave !== blockSave[blockCurrentIndex]) {
        setBlockCurrentSave(blockSave[blockCurrentIndex]);
      }
    }
  }, [blockSave]);


  //function interactive
  const processRowAddInstruction = (newRow) => {
    setRowsInstruction((row) => [...row, {
      id: newRow.id,
      id_mission: newRow.id_mission,
      id_si_instruction: newRow.si_instruction?.id,
      id_instruction_type: newRow.instruction_type.id,
      id_place_of_operations: newRow.place_of_operations?.id,
      begining_date: newRow.begining_date,
      completion_date: newRow.completion_date,
      dhl: newRow.dhl,
      remark: newRow.remark
    }]);
  }

  const processRowUpdateInstruction = (newRow, strict = false) => {
    const updatedRow = !strict ? { ...newRow, id_si_instruction: null } : newRow;
    setRowsInstruction(rowsInstruction.map((row) => (row.id === updatedRow.id ? updatedRow : row)));
    return updatedRow;
  }

  const deleteRowInstruction = (id) => {
    setRowsInstruction(rowsInstruction.filter((row) => row.id !== id));
    if (id.toString().length < 16) setRowsInstructionDeleted((oldRows) => [...oldRows, { id: id }]);
  };

  const saveMissionDetailsInstruction = async (justBlock = false) => {
    if (justBlock) {
      setLoading(true);
      setLoadingUpdate(false);
    }

    try {
      const saveInstructionPromises = [];
      //Saving Mission Instruction
      for (var i = 0; i < rowsInstruction.length; i++) {
        const data = {
          dhl: rowsInstruction[i].dhl,
          remark: rowsInstruction[i].remark,
          id_mission: Number(idMission),
          id_instruction_type: Number(rowsInstruction[i].id_instruction_type),
          id_si_instruction: rowsInstruction[i].id_si_instruction ? Number(rowsInstruction[i].id_si_instruction) : null,
          id_place_of_operations: rowsInstruction[i].id_place_of_operations ? Number(rowsInstruction[i].id_place_of_operations) : null,
          begining_date: rowsInstruction[i].begining_date ? momentDate(rowsInstruction[i].begining_date).format("YYYY-MM-DD") : null,
          completion_date: rowsInstruction[i].completion_date ? momentDate(rowsInstruction[i].completion_date).format("YYYY-MM-DD") : null
        };

        var methode = 'post';
        if (rowsInstruction[i].id.toString().length < 16) {
          methode = 'put';
          data.id_mission_instruction = Number(rowsInstruction[i].id);
        }

        const config = {
          url: MISSION_INSTRUCTION_URL,
          method: methode,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: data
        };
        const response = await axios(config);
        saveInstructionPromises.push(response);

        if (rowsInstruction[i].id.toString().length > 16) {
          rowsInstruction[i].id = Number(response.data.data.id);
          processRowUpdateInstruction(rowsInstruction[i], true);
        }
      }

      //Deleting Mission Instruction
      for (var i = 0; i < rowsInstructionDeleted.length; i++) {
        const config = {
          url: MISSION_INSTRUCTION_URL,
          method: 'delete',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: { id_mission_instruction: Number(rowsInstructionDeleted[i].id) }
        };
        const response = await axios(config);
        saveInstructionPromises.push(response);
      }

      //end treatment
      setRowsInstructionDeleted([]);
      await Promise.all(saveInstructionPromises);

      if (justBlock) {
        setSnackBarMessage("Instructions saved.");
        setSnackBarType("success");
        setOpenSnackBar(true);
        //reload data api
        await getMissionDetail();
      } else {
        setDoneBlockSave(instructionRef);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        if (justBlock) {
          var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
          setSnackBarMessage(errorMsg);
          setSnackBarType("error");
          setOpenSnackBar(true);
        } else {
          setErrorBlockSave(instructionRef);
        }
      }
    } finally {
      if (justBlock) {
        setLoading(false);
        setLoadingUpdate(true);
      }
    }
  }


  return (
    <ContextMissionDetailsInstruction.Provider
      value=
      {{
        rowsInstruction, setRowsInstruction,
        rowsInstructionDeleted, setRowsInstructionDeleted,
        processRowAddInstruction, processRowUpdateInstruction,
        deleteRowInstruction,

        handleClickCancel,
        saveMissionDetailsInstruction
      }}
    >
      {children}
    </ContextMissionDetailsInstruction.Provider>
  );
};

export { MissionDetailsInstructionProvider, ContextMissionDetailsInstruction };