import React, { useEffect, useState, forwardRef, useImperativeHandle, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import { saveAs } from 'file-saver';

import Button from '@mui/material/Button';
import MuiAlert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from '@mui/material/Skeleton';
import SyncIcon from '@mui/icons-material/Sync';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import DownloadIcon from '@mui/icons-material/Download';
import LinearProgress from '@mui/material/LinearProgress';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import EditTypeIcon from '@mui/icons-material/FileOpenOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditRightsIcon from '@mui/icons-material/ManageAccountsOutlined';

import ConfirmDialog from 'components/page/object/ConfirmDialog';
import { isCustomerRole, isInternalRole } from 'components/config/Roles';
import ShippingInstructionFilePopin from '../Popin/ShippingInstructionFilePopin';
import { DataGridPro, GridToolbarContainer, DataGridListValueFormatterCustom } from 'components/page/object/DataGridProCustom';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";

import axios from 'api/axios';

import 'styles/detailsFiles.css'

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ShippingInstructionFiles = forwardRef((props, ref) => {

  const navigate = useNavigate();

  //init param
  const { update } = useContext(ContextBlock);
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));
  const [JLBInternalMode] = useState(isInternalRole(localStorage.getItem("role")));

  //API URL
  const SI_FILE_URL = 'shipping_instruction/si_file';
  const SI_FILES_URL = 'shipping_instruction/si_files';

  //generic
  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);

  //mission data
  const { idShippingInstruction, setIdShippingInstruction } = useContext(ContextShippingInstruction);

  //context
  const { handleRowEditStop } = useContext(ContextBlock);
  const { handleRowEditStart } = useContext(ContextBlock);
  const { CustomNoRowsOverlay } = useContext(ContextBlock);

  //filezone
  const [rowsFile, setRowsFile] = useState([]);
  const [fileToDelete, setFileToDelete] = useState([]);
  const [selectedRowsFile, setSelectedRowsFile] = useState([]);
  const [multiDeleteMode, setMultiDeleteMode] = useState(false);
  const [selectedAllRowsFile, setSelectedAllRowsFile] = useState(false);
  const [multiUpdateTypeMode, setMultiUpdateTypeMode] = useState(false);
  const [multiUpdateRightsMode, setMultiUpdateRightsMode] = useState(false);
  const [confirmFileDeleteOpen, setConfirmFileDeleteOpen] = useState(false);
  const { reloadFileBlock, setReloadFileBlock } = useContext(ContextBlock);

  const [loadingTypes, setLoadingTypes] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [filePermissions, setFilePermissions] = useState([]);
  const [fileWritePermissions, setFileWritePermissions] = useState([]);

  const [type, setType] = useState();
  const [open, setOpen] = useState(false);
  const [permission, setPermission] = useState();
  const [errorFile, setErrorFile] = useState(false);
  const [typesOfFile, setTypesOfFile] = useState([]);
  const [fileUpload, setFileUpload] = useState(false);
  const [syncInProgress, setSyncInProgress] = useState(false);


  useImperativeHandle(ref, () => ({
    getFilesList() {
      return rowsFile;
    },
    getFileTypesList() {
      return typesOfFile;
    },
    getFilePermissionsList() {
      return filePermissions;
    },
    reloadFiles() {
      loadFiles();
    }
  }));

  useEffect(() => {
    //if mission is init
    if (!!idShippingInstruction != false) {
      loadTypes();
      loadFiles();
      loadPermissions();
    }
  }, [idShippingInstruction]);

  useEffect(() => {
    if (reloadFileBlock === true) {
      loadFiles();
      setReloadFileBlock(false);
    }
  }, [reloadFileBlock]);

  // Check/Uncheck the "Select All" checkbox if all rows are selected/unselected and so on
  useEffect(() => {
    const hasRows = rowsFile?.length > 0;
    const hasSelectedRows = selectedRowsFile?.length > 0;
    const hasAllRowsSelected = rowsFile.length === selectedRowsFile.length;

    if ((hasRows && hasSelectedRows && hasAllRowsSelected && !selectedAllRowsFile) ||
      (hasRows && !hasSelectedRows && selectedAllRowsFile) ||
      (!hasRows && selectedAllRowsFile)) {
      handleSelectAllRowsFile();
    }
    if (hasRows && hasSelectedRows && !hasAllRowsSelected && selectedAllRowsFile) {
      setSelectedAllRowsFile(false);
    }
  }, [rowsFile, selectedAllRowsFile, selectedRowsFile])

  // Desactivate multiDeleteMode if delete popin was closed
  useEffect(() => {
    if (!confirmFileDeleteOpen && multiDeleteMode) {
      setMultiDeleteMode(false);
    }
  }, [confirmFileDeleteOpen, multiDeleteMode])


  const loadFiles = async () => {
    try {
      setLoadingFiles(false);
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get(`${SI_FILES_URL}?id_shipping_instruction=${idShippingInstruction}`, config);
      
      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[rowsFromApi.length] = { 
          id: response.data[i].id, 
          file: response.data[i].file.file_name, 
          id_file_permission: response.data[i].file_permission?.id ? Number(response.data[i].file_permission?.id) : null, 
          type: response.data[i].file_type?.id ? Number(response.data[i].file_type?.id) : null, 
          url: response.data[i].file.file_path, 
          mimetype: response.data[i].file.mime_type 
        };
      }
      setRowsFile(rowsFromApi);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingFiles(true);
    }
  }

  const loadTypes = async () => {
    try {
      setLoadingTypes(false);
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get('file_type/list', config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        if (response.data[i].display_in_files_list == 1 && response.data[i].workflow_key !== 'survey_report_marseille') {
          rowsFromApi[rowsFromApi.length] = { value: response.data[i].id, label: response.data[i].label };
        }
      }
      rowsFromApi.sort((a, b) => (a.label > b.label) ? 1 : -1);
      setTypesOfFile(rowsFromApi);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingTypes(true);
    }
  }

  const loadPermissions = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get('file_permission/list', config);

      var apiRows = [], writeRows = [];
      for (var i = 0; i < response.data.length; i++) {
        var row = { value: response.data[i].id, label: response.data[i].label, can_write: response.data[i].can_write, can_be_shared: response.data[i].can_be_shared };
        apiRows[apiRows.length] = row;
        if (row.can_write == 1) {
          writeRows[writeRows.length] = row;
        }
      }
      setFilePermissions(apiRows);
      setFileWritePermissions(writeRows);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const handleDeleteRow = (ids) => {
    setConfirmFileDeleteOpen(true);
    setFileToDelete(ids);
  }

  const handleUploadedFile = (row) => {
    setRowsFile((rowsFile) => [...rowsFile, row]);
  }

  const editRowFile = (event, cellValues) => {
    window.open(cellValues.row.url, "_blank");
  }

  const handleClick = () => {
    //Open File Drop Zone
    setOpen(true);
    //INIT MODAL BEFORE
    setPermission(Number(0));
    setType(Number(0));
    setFileUpload(null);
    setErrorFile(false);
  }

  const handleSelectAllRowsFile = () => {
    if (!selectedAllRowsFile) {
      const tempSelectedRows = [];
      rowsFile.forEach(row => {
        const filePermission = filePermissions.find(obj => {
          return obj.value === row.id_file_permission;
        });
        // Check the permission to select the row
        if (!selectedRowsFile.includes(row.id) && filePermission?.can_write) {
          tempSelectedRows.push(row.id);
        }
      });
      setSelectedAllRowsFile(true)
      setSelectedRowsFile(prevSelectedRows => [...prevSelectedRows, ...tempSelectedRows]);
    } else {
      handleCancelSelectionClick();
    }
  }

  const handleSelectRowsFile = (id) => {
    setSelectedRowsFile(prevSelectedRows => {
      const index = prevSelectedRows.indexOf(id);
      if (index == -1) {
        return [...prevSelectedRows, id];
      } else {
        return prevSelectedRows.filter(item => item !== id);
      }
    });
  }

  const handleCancelSelectionClick = () => {
    setSelectedRowsFile([]);
    setSelectedAllRowsFile(false);
  }

  const handleEditSelectedRowsTypeClick = () => {
    handleClick();
    setMultiUpdateTypeMode(true);

  }

  const handleEditSelectedRowsRightsClick = () => {
    handleClick();
    setMultiUpdateRightsMode(true);
  }

  const handleDeleteSelectedRowsClick = () => {
    handleDeleteRow(selectedRowsFile);
    setMultiDeleteMode(true);
  }

  // Mono update of a row, native to MUI
  const processRowUpdateFile = async (newRow) => {
    var oldRow;
    const updatedRow = { ...newRow, isNew: false };
    setRowsFile(rowsFile.map(function (row) {
      if (row.id === newRow.id) {
        oldRow = row;
      }
      const rowNew = row.id === newRow.id ? updatedRow : row;
      return rowNew
    }));

    try {
      setLoadingFiles(false);
      const data = {
        id_shipping_instruction: idShippingInstruction,
        ids_si_file: [updatedRow.id],
        id_file_permission: updatedRow.id_file_permission,
        id_file_type: updatedRow.type
      }

      var config = {
        url: SI_FILES_URL,
        method: 'put',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: data,
      };
      const reponse = await axios(config);

      if (reponse) {
        setSnackBarMessage("File updated.");
        setSnackBarType("success");
        setOpenSnackBar(true);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setRowsFile(rowsFile.map((row) => (row.id === oldRow.id ? oldRow : row)));
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingFiles(true);
      return updatedRow;
    }
  }

  const deleteRowsFile = async (event, ids) => {
    try {
      setLoadingFiles(false);
      const data = {
        id_shipping_instruction: idShippingInstruction
      };

      if (Array.isArray(ids) && multiDeleteMode) {
        data.ids_si_file = ids;
      } else {
        data.ids_si_file = [ids];
      }

      var config = {
        url: SI_FILES_URL,
        method: 'delete',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: data,
      };
      const reponse = await axios(config);

      setSnackBarMessage("File(s) deleted.");
      setSnackBarType("success");
      setOpenSnackBar(true);

      if (Array.isArray(ids)) {
        setRowsFile(rowsFile.filter((row) => !ids.includes(row.id)));
        setSelectedRowsFile(selectedRowsFile.filter((rowId) => !ids.includes(rowId)))
      } else {
        setRowsFile(rowsFile.filter((row) => row.id !== ids));
        setSelectedRowsFile(selectedRowsFile.filter((rowId) => rowId !== ids))
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingFiles(true);
    }
  }

  const downloadRowFile = async (event, datas) => {
    try {
      const response = await axios.get(
        SI_FILE_URL,
        {
          params: { id_shipping_instruction: idShippingInstruction, id_si_file: datas.row.id },
          responseType: 'arraybuffer',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'content-type': datas.row.mimetype
          },
        },
      );
      // Let the user save the file.
      var blob = new Blob([response.data], { type: datas.row.mimetype });

      saveAs(blob, datas.row.file);

      setSnackBarMessage("File saved in your downloads folder.");
      setSnackBarType("success");
      setOpenSnackBar(true);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const syncFilesFromSharepoint = async () => {
    try {
      var data = new FormData();
      data.append('id_shipping_instruction', idShippingInstruction);

      var jsonRequestData = JSON.stringify(Object.fromEntries(data));

      var config = {
        url: SI_FILES_URL,
        method: 'patch',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: jsonRequestData,
      };
      const response = await axios(config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[rowsFromApi.length] = { id: response.data[i].id, file: response.data[i].file.file_name, id_file_permission: response.data[i]?.file_permission?.id ? Number(response.data[i].file_permission.id) : null, type: response.data[i]?.file_type?.id ? Number(response.data[i].file_type.id) : null, url: response.data[i].file.file_path, mimetype: response.data[i].file.mime_type };
      }
      setRowsFile(rowsFromApi);

      setSyncInProgress(false);
      setSnackBarMessage("Files synchronized with Sharepoint.");
      setSnackBarType("success");
      setOpenSnackBar(true);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        console.log(err);
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  // Multi update of rows
  const multiUpdateRows = async () => {
    try {
      setLoadingFiles(false);

      const data = {
        id_shipping_instruction: idShippingInstruction,
        ids_si_file: selectedRowsFile,
      }
      if (multiUpdateTypeMode) {
        data.id_file_type = Number(type)
      }

      if (multiUpdateRightsMode) {
        data.id_file_permission = Number(permission)
      }

      var config = {
        url: SI_FILES_URL,
        method: 'put',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: data,
      };
      const reponse = await axios(config);

      setSnackBarMessage("Files updated.");
      setSnackBarType("success");
      setOpenSnackBar(true);

    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingFiles(true);
    }

    // Find all rows in "rowsFile" with the same id as "selectedRowsFile" and update their type or id_file_permission
    setRowsFile(
      rowsFile.map((row) => {
        if (selectedRowsFile.includes(row.id)) {
          if (multiUpdateTypeMode) {
            return { ...row, type: Number(type) };
          }
          if (multiUpdateRightsMode) {
            return { ...row, id_file_permission: Number(permission) };
          }
        }
        return row;
      })
    );

    setOpen(false);
    setMultiUpdateTypeMode(false);
    setMultiUpdateRightsMode(false);
    handleCancelSelectionClick();
  }

  const openSharepointFolderBlank = async () => {
    try {
      var config = {
        url: `${SI_FILE_URL}/folderpath?id_shipping_instruction=${idShippingInstruction}`,
        method: 'get',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios(config);
      window.open(response.data.data.si_folder_path, '_blank', 'noreferrer');
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  function EditToolbarFile(params) {

    const handleSyncClick = () => {
      setSyncInProgress(true);
      syncFilesFromSharepoint();
    }

    const handleOpenFolderClick = () => {
      openSharepointFolderBlank();
    }

    return (
      <GridToolbarContainer className="DataGridToolbar">
        {!JLBCustomerMode && (
          <div>
            <div className="toolbarButton left">
              <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Upload File
              </Button>
              <LoadingButton color="primary" loading={syncInProgress} loadingPosition="start" startIcon={<SyncIcon />} onClick={handleSyncClick}>
                Sync from Sharepoint
              </LoadingButton>
            </div>
            <div className="toolbarButton right">
              <Button color="primary" startIcon={<FolderOpenIcon />} onClick={handleOpenFolderClick}>
                Open Sharepoint Folder
              </Button>
            </div>
          </div>
        )}
      </GridToolbarContainer>
    );
  }

  const MultiModificationFooter = () => {
    return (
      <div className="multiModificationFooter">
        {!loadingFiles || !loadingTypes ?
          <div className="skeletonContainer">
            <Skeleton variant="rectangular" className="skeleton" />
          </div>
          :
          <div className="buttonContainer">
            <Tooltip title="Cancel selection" placement="bottom">
              <IconButton
                onClick={handleCancelSelectionClick}
                size='large'
                className="cancelIconButton"
              >
                <CancelIcon fontSize="large" />
              </IconButton>
            </Tooltip>
            <div>{selectedRowsFile?.length} File(s)</div>
            <Tooltip title="Edit Type" placement="bottom">
              <IconButton
                onClick={handleEditSelectedRowsTypeClick}
                size='large'
                className="editTypeIconButton"
              >
                <EditTypeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Rights" placement="bottom">
              <IconButton
                onClick={handleEditSelectedRowsRightsClick}
                size='large'
                className="editRightsIconButton"
              >
                <EditRightsIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="bottom">
              <IconButton
                onClick={handleDeleteSelectedRowsClick}
                size='large'
                className="deleteIconButton"
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          </div>
        }
      </div>
    );
  }

  const columnsFile: GridColDef[] = [
    { field: 'id', headerName: '', editable: false, sortable: false, width: 150,
      renderHeader: (cellValues) => {
        if (JLBInternalMode) {
          return (
            <Checkbox
              checked={selectedAllRowsFile}
              onChange={handleSelectAllRowsFile}
              disabled={!rowsFile?.length > 0 || !loadingFiles || !loadingTypes}
            />
          );
        } else {
          return null;
        }
      },
      renderCell: (cellValues) => {
        const permission = filePermissions.find(obj => {
          return obj.value == cellValues.row.id_file_permission;
        });
        return (
          <div>
            {Boolean(JLBInternalMode && permission?.can_write) && (
              <Checkbox
                checked={selectedRowsFile.includes(cellValues.id)}
                onChange={() => handleSelectRowsFile(cellValues.id)}
              />
            )}

            {Boolean(JLBInternalMode && permission?.can_write) && (
              <IconButton aria-label="delete" onClick={(event) => {
                handleDeleteRow(cellValues.id);
              }}>
                <DeleteIcon />
              </IconButton>
            )}

            {Boolean(JLBInternalMode && permission?.can_write) && (
              <IconButton aria-label="edit" onClick={(event) => {
                editRowFile(event, cellValues);
              }}>
                <EditIcon />
              </IconButton>
            )}

            {!JLBInternalMode && (
              <IconButton aria-label="download" onClick={(event) => {
                downloadRowFile(event, cellValues);
              }}>
                <DownloadIcon />
              </IconButton>
            )}
          </div>
        );
      }
    },
    { field: 'file', headerName: 'File', editable: false, sortable: false, flex: 1 },
    { field: 'type', headerName: 'Type', editable: JLBInternalMode, sortable: true, minWidth: 250, flex: 0.5, type: "singleSelect",
      valueOptions: typesOfFile,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
    },
    { field: 'id_file_permission', headerName: 'Rights', editable: true, sortable: false, width: 250, type: "singleSelect", hide: !JLBInternalMode,
      valueOptions: fileWritePermissions,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
    },
    { field: 'url', headerName: 'url', editable: false, sortable: false, hide: true },
    { field: 'mimetype', headerName: 'mimetype', editable: false, sortable: false, hide: true },
  ];


  //if mission is init
  if (update == 1 && !!idShippingInstruction != false) {
    return (
      <div id="block_files" className="contentDetail block_details">
        <h4>Files</h4>
        <FormControl sx={{ m: 1, width: '100%' }} size="small" id="datagrid-files">
          <DataGridPro
            disableSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            autoHeight
            rows={rowsFile}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdateFile}
            columns={columnsFile}
            experimentalFeatures={{ newEditingApi: true }}
            componentsProps={{
              toolbar: { rowsFile: setRowsFile },
            }}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              Toolbar: EditToolbarFile,
            }}
          />
          <LinearProgress hidden={loadingFiles && loadingTypes} />

          {selectedRowsFile?.length > 0 &&
            <MultiModificationFooter />
          }
        </FormControl>

        <ShippingInstructionFilePopin
          idShippingInstruction={idShippingInstruction}
          handleUploadedFile={handleUploadedFile}
          setSnackBarMessage={setSnackBarMessage}
          setSnackBarType={setSnackBarType}
          setOpenSnackBar={setOpenSnackBar}
          setOpen={setOpen}
          multiUpdateTypeMode={multiUpdateTypeMode}
          setMultiUpdateTypeMode={setMultiUpdateTypeMode}
          multiUpdateRightsMode={multiUpdateRightsMode}
          setMultiUpdateRightsMode={setMultiUpdateRightsMode}
          multiUpdateRows={multiUpdateRows}
          loadingFiles={loadingFiles}
          setFileUpload={setFileUpload}
          setPermission={setPermission}
          setType={setType}
          setErrorFile={setErrorFile}
          open={open}
          permission={permission}
          type={type}
          fileUpload={fileUpload}
          errorFile={errorFile}
          filePermissions={fileWritePermissions}
          typesOfFile={typesOfFile}
          disableTypeNone={'false'}
        />

        <ConfirmDialog
          title={"Delete" + (multiDeleteMode ? (" " + selectedRowsFile?.length + " files") : " file") + " ?"}
          open={confirmFileDeleteOpen}
          setOpen={setConfirmFileDeleteOpen}
          onConfirm={deleteRowsFile}
          params={fileToDelete}
        >
          {"Are you sure you want to delete" + (multiDeleteMode ? " these files" : " this file") + " ?"}
        </ConfirmDialog>
      </div>
    );
  }
});

export default ShippingInstructionFiles;
