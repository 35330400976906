import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";

import {checkSecurity} from 'components/config/Security';
import Menu from './section/Menu';

import { BlockProvider } from "context/ContextBlock";
import { BlockTreatmentProvider } from "context/ContextBlockTreatment";
import { MissionDetailsProvider, ContextMissionDetails } from "context/ContextMissionDetails";
import { MissionWorkflowProvider, ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { MissionDetailsTreatmentProvider } from "context/ContextMissionDetailsTreatment";
import { MissionDetailsReceiverBLProvider } from "./section/MissionDetails/ReceiverBL/ContextMissionDetailsReceiverBL";
import { MissionDetailsHeaderProvider } from './section/MissionDetails/Header/ContextMissionDetailsHeader';
import { MissionDetailsGeneralProvider } from './section/MissionDetails/General/ContextMissionDetailsGeneral';
import { MissionDetailsSIProvider } from './section/MissionDetails/SI/ContextMissionDetailsSI';
import { MissionDetailsInstructionProvider } from './section/MissionDetails/Instruction/ContextMissionDetailsInstruction';
import { MissionDetailsNoteProvider } from './section/MissionDetails/Note/ContextMissionDetailsNote';
import { MissionDetailsSubInvoiceProvider } from './section/MissionDetails/SubInvoice/ContextMissionDetailsSubInvoice';
import { MissionDetailsInvoiceProvider } from './section/MissionDetails/Invoice/ContextMissionDetailsInvoice';
import { MissionDetailsNotificationProvider } from './section/MissionDetails/Notification/ContextMissionDetailsNotification';

import BlockSnackBar from './section/Block/BlockSnackBar';
import BlockInfosPopin from './section/Block/BlockInfosPopin';
import MissionDetailsHeader from './section/MissionDetails/Header/MissionDetailsHeader';
import MissionDetailsCanceled from './section/MissionDetails/MissionDetailsCanceled';
import MissionDetailsFloatingSaveButton from './section/MissionDetails/MissionDetailsFloatingSaveButton';
import MissionDetailsWorkflowSaveButton from './section/MissionDetails/MissionDetailsWorkflowSaveButton';
import MissionDetailsWorkflowMailing from './section/MissionDetails/Mailing/MissionDetailsWorkflowMailing';
import MissionDetailsGeneral from './section/MissionDetails/General/MissionDetailsGeneral';
import MissionDetailsSI from './section/MissionDetails/SI/MissionDetailsSI';
import MissionDetailsInstruction from './section/MissionDetails/Instruction/MissionDetailsInstruction';
import MissionDetailsBL from "./section/MissionDetails/ReceiverBL/BL/MissionDetailsBL";
import MissionDetailsReceiver from './section/MissionDetails/ReceiverBL/Receiver/MissionDetailsReceiver';
import MissionDetailsNote from "./section/MissionDetails/Note/MissionDetailsNote";
import MissionDetailsInvoice from './section/MissionDetails/Invoice/MissionDetailsInvoice';
import MissionDetailsSubInvoice from './section/MissionDetails/SubInvoice/MissionDetailsSubInvoice';
import MissionDetailsProtis from "./section/MissionDetails/Protis/MissionDetailsProtis";
import MissionDetailsFiles from './section/MissionDetails/File/MissionDetailsFiles';
import MissionDetailsNotification from './section/MissionDetails/Notification/MissionDetailsNotification';

import "bootstrap/dist/css/bootstrap.css";
import "styles/globalPortalDashboard.css";
import "styles/detailsCommon.css";
import 'styles/MissionDetails.css';
import "styles/Popin.css";


const MissionDetails = (props) => {

  //init config
  const navigate = useNavigate();

  //init composant ref
  const contentRef = useRef(null);
  const headerRef = useRef("headerComp");
  const generalRef = useRef("generalComp");
  const instructionRef = useRef("instructionComp");
  const receiverRef = useRef("receiverComp");
  const blRef = useRef("blComp");
  const noteRef = useRef("noteComp");
  const invoiceRef = useRef("invoiceComp");
  const subInvoiceRef = useRef("subInvoiceComp");
  const fileRef = useRef("fileForm");
  const protisRef = useRef("protisForm");

  //init context params
  const contextParams = {
    update: props.update,
    refs: {
      contentRef: { ref: contentRef },
      fileRef: { ref: fileRef }
    },
    blocks: {
      generalRef: { ref: generalRef, allowCreation: true, saveOrder: 0 },
      headerRef: { ref: headerRef, allowCreation: true, saveOrder: 1 },
      receiverRef: { ref: receiverRef, workflowLabel: 'receiver_block', allowCreation: true, saveOrder: 2 },
      blRef: { ref: blRef, workflowLabel: 'bl_block', allowCreation: true, saveOrder: 3 },
      noteRef: { ref: noteRef, workflowLabel: ['internal_note', 'public_note'], allowCreation: true, saveOrder: 4 },
      invoiceRef: { ref: invoiceRef, workflowLabel: 'invoice_block', allowCreation: true, saveOrder: 5 },
      subInvoiceRef: { ref: subInvoiceRef, workflowLabel: ['subInvoice_block', 'invoiced_by'], allowCreation: true, saveOrder: 6 },
      protisRef: { ref: protisRef, saveOrder: 7 },
      instructionRef: { ref: instructionRef, workflowLabel: 'instruction_block', saveOrder: 8 }
    }
  };

  //init content
  const [sticky, setSticky] = useState(false);


  useEffect(() => {
    checkSecurity(navigate, "MissionDetails");
  }, []);


  const onScrollContent = e => {
    if (Number(e.currentTarget.scrollTop) > 53) setSticky(true);
    else setSticky(false);
  };
  

  return (
    <div className='FULL-CONTENT DASHBOARD'>
      <BlockProvider value={contextParams}>
        <MissionDetailsProvider>
          <MissionWorkflowProvider>
            <ContextMissionDetails.Consumer>
              {({ idMission, blockRedirect, blockGetData }) => (
                <ContextMissionWorkflow.Consumer>
                  {({ canWrite, workflowLoaded, idButtonWorkflow, setCheckWorkflowData, controlFieldsErrorTruncate }) => (
                    <BlockTreatmentProvider value={{ 
                      idElement: idMission,
                      blockRedirect: blockRedirect, 
                      blockGetData: blockGetData, 

                      canWrite: canWrite,
                      workflowLoaded: workflowLoaded,
                      idButtonWorkflow: idButtonWorkflow,
                      setCheckWorkflowData: setCheckWorkflowData,
                      controlFieldsErrorTruncate: controlFieldsErrorTruncate
                    }}>
                      <MissionDetailsTreatmentProvider>
                        <MissionDetailsHeaderProvider>
                          <MissionDetailsGeneralProvider>
                            <MissionDetailsInstructionProvider>
                              <MissionDetailsSIProvider>
                                <MissionDetailsReceiverBLProvider>
                                  <MissionDetailsNoteProvider>
                                    <MissionDetailsSubInvoiceProvider>
                                      <MissionDetailsInvoiceProvider>
                                        <MissionDetailsNotificationProvider>
                                          <div className="content" onScroll={onScrollContent}>
                                            <div ref={contentRef}>
                                              <div>
                                                <MissionDetailsHeader floating={sticky}/>
                                                <MissionDetailsCanceled/>
                                                <MissionDetailsSI/>
                                                <MissionDetailsGeneral ref={generalRef}/>
                                                <MissionDetailsInstruction ref={instructionRef}/>

                                                <MissionDetailsReceiver ref={receiverRef}/>
                                                <MissionDetailsBL ref={blRef}/>

                                                <MissionDetailsNote ref={noteRef}/>
                                                <MissionDetailsSubInvoice ref={subInvoiceRef}/>
                                                <MissionDetailsInvoice ref={invoiceRef}/>
                                                <MissionDetailsFiles ref={fileRef}/>
                                                <MissionDetailsProtis fileForm={fileRef} />
                                                <MissionDetailsNotification/>
                                                <MissionDetailsWorkflowMailing/>
                                                <MissionDetailsWorkflowSaveButton/>
                                              </div>
                                              <MissionDetailsFloatingSaveButton/>
                                              <BlockInfosPopin />
                                              <BlockSnackBar />
                                            </div>
                                          </div>
                                        </MissionDetailsNotificationProvider>
                                      </MissionDetailsInvoiceProvider>
                                    </MissionDetailsSubInvoiceProvider>
                                  </MissionDetailsNoteProvider>
                                </MissionDetailsReceiverBLProvider>
                              </MissionDetailsSIProvider>
                            </MissionDetailsInstructionProvider>
                          </MissionDetailsGeneralProvider>
                        </MissionDetailsHeaderProvider>
                      </MissionDetailsTreatmentProvider>
                    </BlockTreatmentProvider>
                  )}
                </ContextMissionWorkflow.Consumer>
              )}
            </ContextMissionDetails.Consumer>
          </MissionWorkflowProvider>
        </MissionDetailsProvider>
      </BlockProvider>
      <Menu /> 
    </div>
  );
};

export default MissionDetails;
