import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from 'react';
import MuiAlert from '@mui/material/Alert';
import '../../../../styles/globalPortalDashboard.css';
import '../../../../styles/DashboardList.css';
import 'bootstrap/dist/css/bootstrap.css';

import {checkSecurity} from '../../../config/Security';
import { FilterProvider } from "../../../../context/ContextFilter";
import PortMissionsListFilter from "./PortMissionsListFilter";
import PortMissionsList  from './PortMissionsList'
import Menu from '../Menu';

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PortDashboard = (props) => {
    // CHECK SECURITY
    const navigate = useNavigate();

    useEffect(() => {
      checkSecurity(navigate, "PortDashboard");
    }, []);
    // END SECURITY

    const filterRef = useRef("filterRef");

    function initFilterMissions(totalsRowParam){
      filterRef.current.initFilter(totalsRowParam)
    }

    return (
      <div className='FULL-CONTENT DASHBOARD'>
        <FilterProvider>
          <div className="content">
            <PortMissionsListFilter 
              ref={filterRef} 
              archivedList={props.archivedList}
              // Set visible filters
              visibleFilters={{
                principalsFilter: true,  
                missionTypeFilter: true, 
                insuredFilter: true, 
                receiverFilter: true,
                vesselFilter: true,
                statusFilter: true, 
              }}
              // Set visible categories 
              visibleCategories={{ 
                refJLBCategory: true, 
                portCategory: true,
                principalsCategory: true, 
                vesselCategory: true,
                billOfLadingCategory: true, 
                warehouseCategory: true,
                receiverCategory: true,
                cargoCategory: true,
                traderCategory: true,
                insuredCategory: true,
              }}   
            />
            <div className="contentList">
              <PortMissionsList 
                initFilterMissions={initFilterMissions} 
                archivedList={props.archivedList}
                // Set visible columns
                visibleColumns={{
                  missionColorColumn: true,
                  refJLBColumn: true,
                  internalNoteColumn: true,
                  createdDateColumn: true,
                  statusColumn: true,
                  portColumn: true,
                  principalsColumn: true,
                  insuredColumn: true,
                  vesselColumn: true,
                  tonnageColumn: true,
                  receiverColumn: true,
                  billOfLadingColumn: true,
                  warehouseColumn: true,
                }} 
              />
            </div>
          </div>
        </FilterProvider>
        <Menu />
      </div>
    )
}

export default PortDashboard
