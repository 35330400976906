import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import momentDate from 'moment';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";

import axios from 'api/axios';


const ContextMissionDetailsSubInvoice = createContext();

const MissionDetailsSubInvoiceProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const { subInvoiceRef } = useContext(ContextMissionDetails);
  const { getMissionDetail } = useContext(ContextMissionDetails);

  //API URL
  const MISSION_URL = 'mission';

  //generic
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);
  const { setSnackErrorMsg } = useContext(ContextBlock);

  //mission state
  const {general, setGeneral} = useContext(ContextMissionDetails);
  const { idMission, setIdMision } = useContext(ContextMissionDetails);

  //treament
  const [reloadData, setReloadData] = useState(0);
  const [oldReloadData, setOldReloadData] = useState(0);
  const [blockCurrentEdit, setBlockCurrentEdit] = useState({});
  const [blockCurrentSave, setBlockCurrentSave] = useState({});
  
  const { setDoneBlockSave } = useContext(ContextBlock);
  const { setErrorBlockSave } = useContext(ContextBlock);
  const { setReloadBlockData } = useContext(ContextBlock);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { blockSave, setBlockSave } = useContext(ContextBlock);
  const {saveBlockAndCheckWorkflow} = useContext(ContextMissionDetailsTreatment);

  const { handleClickBlockCancel } = useContext(ContextBlock);
  const handleClickCancel = async () => {
    handleClickBlockCancel(subInvoiceRef);
  }

  //init sub invoice data
  const {subInvoices, setSubInvoices} = useContext(ContextMissionDetails);

  const [rowsSubInvoice, setRowsSubInvoice] = useState([]);
  const [rowsSubInvoiceView, setRowsSubInvoiceView] = useState([]);
  const [rowsSubInvoiceDeleted, setRowsSubInvoiceDeleted] = useState([]);
  //INIT general data
  useEffect(() => {
    //load data
    if (Object.keys(general).length > 0) {
      //dont update data if edition is in progress
      var blockInEdit  = blockEdit.findIndex(obj => (obj.blockRef === subInvoiceRef && obj.onEdit === false));
      if (blockInEdit >= 0) {
        //(RE)INIT detect change
        if (reloadData > oldReloadData) {
          setOldReloadData(reloadData);
        }
      }
    }
  }, [general, reloadData]);

  //INIT Sub Invoices
  useEffect(() => {
    //load data form
    if (subInvoices) {
      if (subInvoices.length > 0) {
        //dont update data if edition is in progress
        var blockInEdit  = blockEdit.findIndex(obj => (obj.blockRef === subInvoiceRef && obj.onEdit === false));
        if (blockInEdit >= 0) {
          //(RE)INIT detect change
          if (reloadData > oldReloadData) {
            setOldReloadData(reloadData);
          }
          
          var rowsFromApi = [];
          for (var i=0; i < subInvoices.length; i++) {
            var invoice_file = null;
            if (subInvoices[i].invoice_file) {
              invoice_file = subInvoices[i].invoice_file.file;
              //for treatment, id = id_mission_file;
              invoice_file.id_file = invoice_file.id;
              invoice_file.id = subInvoices[i].invoice_file.id;
            }
            rowsFromApi[i] = {
              id:subInvoices[i].id,
              number: subInvoices[i].number,
              date: subInvoices[i].date,
              file: invoice_file
            };
          }
          setRowsSubInvoice(rowsFromApi);
        }
      }
    }
  }, [subInvoices, reloadData]);

  useEffect(() => {
    //load data view
    if (rowsSubInvoice) {
      if (rowsSubInvoice.length > 0) {
        var rowsSubInvoiceApi = [];
        for(var i=0; i < rowsSubInvoice.length; i++) {
          rowsSubInvoiceApi[i] = {};
          rowsSubInvoiceApi[i].id = rowsSubInvoice[i].id;
          rowsSubInvoiceApi[i].number = rowsSubInvoice[i].number;
          if(rowsSubInvoice[i].date) rowsSubInvoiceApi[i].date = momentDate(rowsSubInvoice[i].date).format("DD MMM YY");
          else rowsSubInvoiceApi[i].date = "";
          if(rowsSubInvoice[i].file)
            rowsSubInvoiceApi[i].file = rowsSubInvoice[i].file;
          else
            rowsSubInvoiceApi[i].file = "-";
        }
        setRowsSubInvoiceView(rowsSubInvoiceApi);
      }
    }
  }, [rowsSubInvoice]);

  //check block current edit
  useEffect(() => {
    //maj reload state
    if (reloadData == oldReloadData) {
      if (blockCurrentEdit?.reload === true) {
        setReloadData(reloadData + 1);
        setReloadBlockData(subInvoiceRef, false);
      }
    }
  }, [blockCurrentEdit]);

  //check block edit
  useEffect(() => {
    //load current block
    var blockCurrentIndex  = blockEdit.findIndex(obj => obj.blockRef === subInvoiceRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentEdit !== blockEdit[blockCurrentIndex]) {
        setBlockCurrentEdit(blockEdit[blockCurrentIndex]);
      }
    }
  }, [blockEdit]);

  //init block save
  useEffect(() => {
    saveBlockAndCheckWorkflow(subInvoiceRef, saveSubInvoices);
  }, [blockSave, idMission]);

  //check block save
  useEffect(() => {
    //load current block
    var blockCurrentIndex  = blockSave.findIndex(obj => obj.blockRef === subInvoiceRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentSave !== blockSave[blockCurrentIndex]) {
        setBlockCurrentSave(blockSave[blockCurrentIndex]);
      }
    }
  }, [blockSave]);


  //function
  const processRowUpdateSubInvoice = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRowsSubInvoice(rowsSubInvoice.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const saveSubInvoices = async (justBlock = false) => {
    if (justBlock) {
      setLoading(true);
      setLoadingUpdate(false);
    }

    try {
      var saveSubInvoicePromises = [];
      //Saving Subinvoices
      for (var i = 0; i < rowsSubInvoice.length; i++) {
        var data = new FormData();
        data.append('id_mission', Number(idMission));
        data.append('number', rowsSubInvoice[i].number);
        if (rowsSubInvoice[i].date) data.append('date', momentDate(rowsSubInvoice[i].date).format("YYYY-MM-DD"));
        else data.append('date', null);

        if (rowsSubInvoice[i].file) data.append('id_mission_file', rowsSubInvoice[i].file.id);
        else data.append('id_mission_file', null);

        var methode = 'post';
        if (rowsSubInvoice[i].id.toString().length < 16) {
          methode = 'put';
          data.append('id_mission_subcontractor_invoice', rowsSubInvoice[i].id);
        }

        var jsonRequestDataSubInvoice = JSON.stringify(Object.fromEntries(data));
        var jsonRequestDataFinal = jsonRequestDataSubInvoice.replace(/"null"/g, 'null');

        var config = {
          url: MISSION_URL+'/subcontractor/invoice',
          method: methode,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: jsonRequestDataFinal
        };
        const response = await axios(config);
        saveSubInvoicePromises.push(response);

        if (rowsSubInvoice[i].id.toString().length > 16) {
          rowsSubInvoice[i].id = response.data.data.id;
        }
        processRowUpdateSubInvoice(rowsSubInvoice);
      }

      //Deleting Sub invoices
      for (var i = 0; i < rowsSubInvoiceDeleted.length; i++) {
        var data = new FormData();
        data.append('id_mission', Number(idMission));
        data.append('id_mission_subcontractor_invoice', rowsSubInvoiceDeleted[i].id);

        var jsonRequestDataReceiver = JSON.stringify(Object.fromEntries(data));
        
        var config = {
          url: MISSION_URL+'/subcontractor/invoice',
          method: 'delete',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: jsonRequestDataReceiver
        };
        const response = await axios(config);
        saveSubInvoicePromises.push(response);
      }

      //end treatment
      setRowsSubInvoiceDeleted([]);
      await Promise.all(saveSubInvoicePromises);

      if (justBlock) {
        setSnackBarMessage("Subcontracting Invoices saved.");
        setSnackBarType("success");
        setOpenSnackBar(true);
        //reload data api
        await getMissionDetail();
      } else {
        setDoneBlockSave(subInvoiceRef);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        if (justBlock) {
          var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
          setSnackBarMessage(errorMsg);
          setSnackBarType("error");
          setOpenSnackBar(true);
        } else {
          setErrorBlockSave(subInvoiceRef);
        }
      }
    } finally {
      if (justBlock) {
        setLoading(false);
        setLoadingUpdate(true);
      }
    }
  }


  return (
    <ContextMissionDetailsSubInvoice.Provider
      value=
      {{
        rowsSubInvoice, setRowsSubInvoice,
        rowsSubInvoiceView, setRowsSubInvoiceView,
        rowsSubInvoiceDeleted, setRowsSubInvoiceDeleted,

        processRowUpdateSubInvoice,

        handleClickCancel,
        saveSubInvoices
      }}
    >
      {children}
    </ContextMissionDetailsSubInvoice.Provider>
  );
};

export { MissionDetailsSubInvoiceProvider, ContextMissionDetailsSubInvoice };
