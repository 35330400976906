import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, forwardRef } from 'react';
import {checkSecurity} from 'components/config/Security';
import MissionsList  from './section/MissionsList'
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { saveAs } from 'file-saver';
import Moment from 'moment';

//FILTER
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {GridLinkOperator} from '@mui/x-data-grid-pro';
import FilterCheckBox from '../filter/FilterCheckBox'
import FilterStatusList from '../filter/FilterStatusList'
//END FILTER
import Menu from './section/Menu';
import 'styles/globalPortalDashboard.css';
import 'styles/DashboardList.css';
import 'bootstrap/dist/css/bootstrap.css';

import axios from 'api/axios';
const EXPORTMISSIONLIST_URL = 'mission/export/customer';

const Alert = forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Portal = () => {
    // CHECK SECURITY
    const navigate = useNavigate();

    useEffect(() => {
      checkSecurity(navigate);
      var filter = JSON.parse(localStorage.getItem("filter"));

      if (filter)
      {
        //INIT DATAS Filter list
        setFilterDatas(filter.datas);

        //INIT FILTER Status
        if (filter.status)
        {
          filter.status.map((itemStatus) => {
              setFilterStatus(filter.status, itemStatus, true, true);
          });
        }

        //INIT SEARCH TXT CATEGORY
        if (filter.search || filter.category)
        {
          setSearchTxt(filter.search);
          setCategory(filter.category);
        }
        resetFilterResetButton(filter.datas, filter.status, filter.search, filter.category);
      }
    }, []);
    // END SECURITY

    const [openSnackBar, setOpenSnackBar] = useState(false);

    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState([]);
    const [vesselRows, setVesselRows] = useState([]);
    const [surveyRows, setSurveyRows] = useState([]);
    const [principalRows, setPrincipalRows] = useState([]);
    const [missionRows, setMissionRows] = useState([]);
    const [portRows, setPortRows] = useState([]);
    const [insuredRows, setInsuredRows] = useState([]);
    const [receiverRows, setReceiverRows] = useState([]);
    const [loadingGrid, setLoadingGrid] = useState(false);
    const [dataGridLoaded, setDataGridLoaded] = useState(false);

    const [survey, setSurvey] = useState('');
    const [principals, setPrincipals] = useState('');
    const [missionType, setMissionType] = useState('');
    const [port, setPort] = useState('');
    const [insured, setInsured] = useState('');
    const [receiver, setReceiver] = useState('');
    const [vessel, setVessel] = useState('');
    const [cancel, setCancel] = useState('');

    const [category, setCategory] = useState('');
    const [searchTxt, setSearchTxt] = useState('');
    const [searchTxtState, setSearchTxtState] = useState('');

    const [filterModel, setFilterModel] = useState({items: []});
    const [filterDatas, setFilterDatas] = useState({survey:'',principals:'',missionType:'',port:'',insured:'',receiver:'',vessel:'', cancel:''});
    const [filterStatusArray, setFilterStatusArray] = useState([]);

    const [filterOn, setFilterOn] = useState(false);
    const [statusFilter1, setStatusFilter1] = useState(false);
    const [resetStatusFilter1, setResetStatusFilter1] = useState(false);
    const [statusFilter2, setStatusFilter2] = useState(false);
    const [resetStatusFilter2, setResetStatusFilter2] = useState(false);
    const [statusFilter3, setStatusFilter3] = useState(false);
    const [resetStatusFilter3, setResetStatusFilter3] = useState(false);
    const [statusFilter4, setStatusFilter4] = useState(false);
    const [resetStatusFilter4, setResetStatusFilter4] = useState(false);
    const [statusFilter5, setStatusFilter5] = useState(false);
    const [resetStatusFilter5, setResetStatusFilter5] = useState(false);
    const [statusFilter6, setStatusFilter6] = useState(false);
    const [resetStatusFilter6, setResetStatusFilter6] = useState(false);
    const [statusFilter7, setStatusFilter7] = useState(false);
    const [resetStatusFilter7, setResetStatusFilter7] = useState(false);
    const [statusFilter8, setStatusFilter8] = useState(false);
    const [resetStatusFilter8, setResetStatusFilter8] = useState(false);
    const [statusFilter9, setStatusFilter9] = useState(false);
    const [resetStatusFilter9, setResetStatusFilter9] = useState(false);
    const [statusFilter10, setStatusFilter10] = useState(false);
    const [resetStatusFilter10, setResetStatusFilter10] = useState(false);

    const [visibleRows, setVisibleRows] = useState([]);
    const [isDownload, setIsDownload] = useState(false);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setIsDownload(false);
    };

    function setFilterSelectedLocalStorage(filterDataParam, filterStatusArray, searchTxt, category)
    {
      var filter = {datas:filterDataParam, status:filterStatusArray, search:searchTxt, category:category};
      localStorage.setItem("filter", JSON.stringify(filter));
    }

    function selectDataContent(filterDataParam, filterStatusArray, searchTxtParam, categoryParam, totalRowsParam)
    {
      var searchTxtVar = searchTxt;
      var categoryVar = category;
      var totalRowsVar = totalRows;
      if (searchTxtParam || categoryParam || totalRowsParam)
      {
        searchTxtVar = searchTxtParam;
        categoryVar = categoryParam;
        totalRowsVar = totalRowsParam;
      }
      setLoadingGrid(true);
      var selectedRows = [];
      var addRow = true;
      for(var i=0; i < totalRowsVar.length; i++) {
        addRow = true;
        if (filterDataParam.survey.length > 0 && totalRowsVar[i].survey_handler !== filterDataParam.survey) addRow = false;
        if (filterDataParam.principals.length > 0 && totalRowsVar[i].principals !== filterDataParam.principals) addRow = false;
        if (filterDataParam.missionType.length > 0 && totalRowsVar[i].missionTypeLabel !== filterDataParam.missionType) addRow = false;
        if (filterDataParam.port.length > 0 && totalRowsVar[i].port !== filterDataParam.port) addRow = false;
        if (filterDataParam.insured.length > 0 && totalRowsVar[i].insured !== filterDataParam.insured) addRow = false;
        if (filterDataParam.receiver.length > 0 && totalRowsVar[i].receiver.indexOf(filterDataParam.receiver) < 0) addRow = false;
        if (filterDataParam.vessel.length > 0 && totalRowsVar[i].vessel !== filterDataParam.vessel) addRow = false;
        //if filter canceled = 'no' => we dont want the line
        if (filterDataParam.cancel.length == 2 && !!totalRowsVar[i].missionCancelReason != false) addRow = false;
        //if filter canceled = 'yes' => we want just the line
        if (filterDataParam.cancel.length == 3 && !!totalRowsVar[i].missionCancelReason == false) addRow = false;

        //Status filter
        if (filterStatusArray.length > 0)
        {
          if (!filterStatusArray.includes(totalRowsVar[i].status.statut_type)) addRow = false;
        }

        if (addRow) selectedRows[selectedRows.length] = totalRowsVar[i];
      }

      setRows(selectedRows);
      setLoadingGrid(false);
      setFilterSelectedLocalStorage(filterDataParam, filterStatusArray, searchTxtVar, categoryVar);
      resetFilterResetButton(filterDataParam, filterStatusArray, searchTxtVar, categoryVar);
    }

    function initFilterMissions(totalRowsParam)
    {
      var filter = JSON.parse(localStorage.getItem("filter"));

      if (filter)
      {
        setSurvey(filter.datas.survey);
        setPrincipals(filter.datas.principals);
        setMissionType(filter.datas.missionType);
        setPort(filter.datas.port);
        setInsured(filter.datas.insured);
        setReceiver(filter.datas.receiver);
        setVessel(filter.datas.vessel);
        setCancel(filter.datas.cancel);

        selectDataContent(filter.datas, filter.status, filter.search, filter.category, totalRowsParam);
        setSearchTxtAction(filter.search, filter.category, filter.datas,  filter.status);
      }
    }

    function setSearchTxtAction(searchTxtAct, cat, filterDatasParam, filterStatusArrayParam)
    {
      var filterDatasVar = filterDatas;
      if (filterDatasParam) filterDatasVar = filterDatasParam;
      var filterStatusArrayVar = filterStatusArray;
      if (filterStatusArrayParam) filterStatusArrayVar = filterStatusArrayParam;

      if (searchTxtAct.length > 0)
        {
          if (cat.length < 1)
          {
            setFilterModel({
                items: [
                  {id: 1,columnField: "ref", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 10,columnField: "principalsRef", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 2,columnField: "port", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 3,columnField: "principals", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 4,columnField: "vessel", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 5,columnField: "b_o_l", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 6,columnField: "warehouse", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 7,columnField: "receiverSearch", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 8,columnField: "cargo", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 9,columnField: "trader", operatorValue: 'contains', value: searchTxtAct,},
                ],
                linkOperator: GridLinkOperator.Or,
              });
          }
          else {
            setFilterModel({
                items: [
                  {
                    columnField: cat,
                    operatorValue: 'contains',
                    value: searchTxtAct,
                  },
                ],
              });
          }
        }
        else
        {
            setFilterModel({items: []});
        }
        setFilterSelectedLocalStorage(filterDatasVar, filterStatusArrayVar, searchTxtAct, cat);
        resetFilterResetButton(filterDatasVar, filterStatusArrayVar, searchTxtAct, cat);
    }

    const handleChangeCategory = (event) => {
      setCategory(event.target.value);
      setSearchTxtAction(searchTxt, event.target.value);
    };

    const handleChangeSearchText = (event) => {
      setSearchTxt(event.target.value);
      if (event.target.value.length > 0)
      {
        setSearchTxtState("");
        setSearchTxtAction(event.target.value, category);
      }
      else {
        if (event.target.value.length === 0) {
          setSearchTxtState("");
          setSearchTxtAction(event.target.value, category);
        }
        else {
          setSearchTxtState("warning");
        }
      }
    }
    //SETTER
    function createSurveyHandler(data) {
      return {id:data.id, alias:data.firstname+" "+data.lastname};
    }

    function createMissionTypeHandler(data) {
      return {id:data.nomenclature, alias:data.label};
    }

    function createPortHandler(data) {
      return {id:data.nomenclature, alias:data.label};
    }

    function createInsuredHandler(data) {
      return {id:data.id, alias:data.name};
    }

    function createReceiverHandler(data) {
      return {id:data.id, alias:data.name};
    }

    function createVesselHandler(data) {
      return {id:data.id, alias:data.name};
    }

    function createCancelHandler(data) {
      return {id:data.id, alias:data.name};
    }
    //Filter
    function setSurveyHandler(data) {
      setSurvey(data);
      var filterData = {survey:data,principals:principals,missionType:missionType,port:port,insured:insured,receiver:receiver,vessel:vessel,cancel:cancel};
      setFilterDatas(filterData);
      selectDataContent(filterData, filterStatusArray);
    }

    function setMissionTypeHandler(data) {
      setMissionType(data);
      var filterData = {survey:survey,principals:principals,missionType:data,port:port,insured:insured,receiver:receiver,vessel:vessel,cancel:cancel};
      setFilterDatas(filterData);
      selectDataContent(filterData, filterStatusArray);
    }

    function setPortHandler(data) {
      setPort(data);
      var filterData = {survey:survey,principals:principals,missionType:missionType,port:data,insured:insured,receiver:receiver,vessel:vessel,cancel:cancel};
      setFilterDatas(filterData);
      selectDataContent(filterData, filterStatusArray);
    }

    function setInsuredHandler(data) {
      setInsured(data);
      var filterData = {survey:survey,principals:principals,missionType:missionType,port:port,insured:data,receiver:receiver,vessel:vessel,cancel:cancel};
      setFilterDatas(filterData);
      selectDataContent(filterData, filterStatusArray);
    }

    function setReceiverHandler(data) {
      setReceiver(data);
      var filterData = {survey:survey,principals:principals,missionType:missionType,port:port,insured:insured,receiver:data,vessel:vessel,cancel:cancel};
      setFilterDatas(filterData);
      selectDataContent(filterData, filterStatusArray);
    }

    function setVesselHandler(data) {
      setVessel(data);
      var filterData = {survey:survey,principals:principals,missionType:missionType,port:port,insured:insured,receiver:receiver,vessel:data,cancel:cancel};
      setFilterDatas(filterData);
      selectDataContent(filterData, filterStatusArray);
    }

    function setCancelHandler(data) {
      setCancel(data);
      var filterData = {survey:survey,principals:principals,missionType:missionType,port:port,insured:insured,receiver:receiver,vessel:vessel,cancel:data};
      setFilterDatas(filterData);
      selectDataContent(filterData, filterStatusArray);
    }

    function setFilterStatus(filter, index, value, init)
    {
      setFilterStatusArray(filter);
      if (!init) selectDataContent(filterDatas, filter);
      if (index == 1) {setStatusFilter1(value);}
      else if (index == 2) {setStatusFilter2(value);}
      else if (index == 3) {setStatusFilter3(value);}
      else if (index == 4) {setStatusFilter4(value);}
      else if (index == 5) {setStatusFilter5(value);}
      else if (index == 6) {setStatusFilter6(value);}
      else if (index == 7) {setStatusFilter7(value);}
      else if (index == 8) {setStatusFilter8(value);}
      else if (index == 9) {setStatusFilter9(value);}
      else if (index == 10) {setStatusFilter10(value);}
    }

    function resetFilterResetButton(filterDatas, filter, searchTxt, category)
    {
      var showFilter = false;
      if (filter.length > 0) showFilter = true;
      if (filterDatas.survey !== ''
          || filterDatas.principals !== ''
          || filterDatas.missionType !== ''
          || filterDatas.port !== ''
          || filterDatas.insured !== ''
          || filterDatas.receiver !== ''
          || filterDatas.vessel) showFilter = true;
      if (searchTxt !== '') showFilter = true;
      if (category !== '') showFilter = true;

      setFilterOn(showFilter);
    }

    function handleResetAll()
    {
      setSurvey('');
      setPrincipals('');
      setMissionType('');
      setPort('');
      setInsured('');
      setReceiver('');
      setVessel('');
      setCancel('');

      setVesselRows([]);
      setSurveyRows([]);
      setPrincipalRows([]);
      setMissionRows([]);
      setPortRows([]);
      setInsuredRows([]);
      setReceiverRows([]);
      setFilterStatusArray([]);

      setCategory('');
      setSearchTxt('');
      setSearchTxtState("");

      setResetStatusFilter1(true);
      setStatusFilter1(false);
      setResetStatusFilter2(true);
      setStatusFilter2(false);
      setResetStatusFilter3(true);
      setStatusFilter3(false);
      setResetStatusFilter4(true);
      setStatusFilter4(false);
      setResetStatusFilter5(true);
      setStatusFilter5(false);
      setResetStatusFilter6(true);
      setStatusFilter6(false);
      setResetStatusFilter7(true);
      setStatusFilter7(false);
      setResetStatusFilter8(true);
      setStatusFilter8(false);
      setResetStatusFilter9(true);
      setStatusFilter9(false);
      setResetStatusFilter10(true);
      setStatusFilter10(false);

      var filterData = {survey:'',principals:'',missionType:'',port:'',insured:'',receiver:'',vessel:''};
      setFilterDatas(filterData);
      setSearchTxtAction("", "");
      selectDataContent(filterData, []);
      setFilterOn(false);
      localStorage.setItem("filter", null);
    }

    const handleExport = async (e) => {
      if (visibleRows.length > 0)
      {
        try {

          var config = {
            url: EXPORTMISSIONLIST_URL,
            responseType: 'arraybuffer',
            method: "post",
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
              'Content-Type': 'application/json'
            },
            data: { id: visibleRows },
          };
          const response = await axios(config);

          // Let the user save the file.
          var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          saveAs(blob, 'Export_files_'+Moment().format('YYYYMMDD-HHmmss')+'.xlsx');
          setIsDownload(true);

        } catch (err) {
          if (err.response?.status === 401) {
              navigate("/logout", { replace: true });
          }
          else {
            setOpenSnackBar(true);
          }
        }
      }
    }

    return (
        <div className='FULL-CONTENT DASHBOARD'>
          <div className="content">
            <div className="contentFilter">
              <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '99%' }} className="FilterSearchBlockField">
                <Box className='SearchBoxText' sx={{ width: '99%' }}>
                  <SearchRoundedIcon sx={{ color: 'action.active' }} className='iconeLeft'/>
                  <FormControl variant="standard"  sx={{ m: 1, minWidth: 300, width: '100%', paddingLeft:3, paddingRight:2 }}>
                    <TextField id="input-search-with-sx" label="Search" variant="standard" value={searchTxt} fullWidth onChange={handleChangeSearchText} color={searchTxtState} />
                  </FormControl>
                </Box>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="category-simple-select-standard-label">Category</InputLabel>
                  <Select
                    labelId="category-simple-select-standard-label"
                    id="category-simple-select-standard"
                    value={category}
                    onChange={handleChangeCategory}
                    label="Category"
                  >
                    <MenuItem value=""><em>All</em></MenuItem>
                    <MenuItem value="principalsRef">Your Ref.</MenuItem>
                    <MenuItem value="ref">Ref. JLB</MenuItem>
                    <MenuItem value="port">Port</MenuItem>
                    <MenuItem value="vessel">Vessel (BL)</MenuItem>
                    <MenuItem value="b_o_l">Bill of lading</MenuItem>
                    <MenuItem value="warehouse">Warehouse</MenuItem>
                    <MenuItem value="receiver">Receiver</MenuItem>
                    <MenuItem value="cargo">Cargo</MenuItem>
                    <MenuItem value="trader">Trader</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="standard"  sx={{ display: 'inline', m: 1, minWidth: 300 }}>
                  <FilterStatusList
                    setFilter={setFilterStatus}
                    filterStatus={filterStatusArray}
                    statusFilter={[
                      statusFilter1,
                      statusFilter2,
                      statusFilter3,
                      statusFilter4,
                      statusFilter5,
                      statusFilter6,
                      statusFilter7,
                      statusFilter8,
                      statusFilter9,
                      statusFilter10
                    ]}
                    statusFilterReset={[
                      resetStatusFilter1,
                      resetStatusFilter2,
                      resetStatusFilter3,
                      resetStatusFilter4,
                      resetStatusFilter5,
                      resetStatusFilter6,
                      resetStatusFilter7,
                      resetStatusFilter8,
                      resetStatusFilter9,
                      resetStatusFilter10
                    ]}
                    changeStatusFilter={[
                      setResetStatusFilter1,
                      setResetStatusFilter2,
                      setResetStatusFilter3,
                      setResetStatusFilter4,
                      setResetStatusFilter5,
                      setResetStatusFilter6,
                      setResetStatusFilter7,
                      setResetStatusFilter8,
                      setResetStatusFilter9,
                      setResetStatusFilter10
                    ]}
                    JLBInternalMode={false}
                  />
                </FormControl>
                <div className={filterOn ? 'resetButtonFilter' : 'resetButtonFilter hide'} onClick={handleResetAll}><i className="bx bx-reset"></i><span>Reset all</span></div>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }} className="FilterSearchBlockFieldBottom">
                {Boolean(dataGridLoaded) && (
                  <FilterCheckBox
                    title="Survey Handler"
                    width="200"
                    api="survey_handler/list"
                    apiParam=""
                    apiParamValue=""
                    creator={createSurveyHandler}
                    setValueOfFilterAndSearch={setSurveyHandler}
                    dataValueHandler={survey}
                    setDataValueHandler={setSurvey}
                    datas={surveyRows}
                    loadByList='true'
                  />
                )}

                {Boolean(dataGridLoaded) && (
                  <FilterCheckBox
                    title="Mission type"
                    width="200"
                    api="mission_type/list"
                    apiParam="hide_cocoa_departure"
                    apiParamValue="1"
                    creator={createMissionTypeHandler}
                    setValueOfFilterAndSearch={setMissionTypeHandler}
                    dataValueHandler={missionType}
                    setDataValueHandler={setMissionType}
                    datas={missionRows}
                    loadByList='true'
                  />
                )}

                {Boolean(dataGridLoaded) && (
                  <FilterCheckBox
                    title="Port"
                    width="200"
                    api="port/list"
                    apiParam=""
                    apiParamValue=""
                    creator={createPortHandler}
                    setValueOfFilterAndSearch={setPortHandler}
                    dataValueHandler={port}
                    setDataValueHandler={setPort}
                    datas={portRows}
                    loadByList='true'
                  />
                )}

                {Boolean(dataGridLoaded) && (
                  <FilterCheckBox
                    title="Insured"
                    width="200"
                    api="company/list"
                    apiParam="company_role"
                    apiParamValue="Insured"
                    creator={createInsuredHandler}
                    setValueOfFilterAndSearch={setInsuredHandler}
                    dataValueHandler={insured}
                    setDataValueHandler={setInsured}
                    datas={insuredRows}
                    loadByList='true'
                  />
                )}

                {Boolean(dataGridLoaded) && (
                  <FilterCheckBox
                    title="Receiver"
                    width="200"
                    api="company/list"
                    apiParam="company_role"
                    apiParamValue="Receiver"
                    creator={createReceiverHandler}
                    setValueOfFilterAndSearch={setReceiverHandler}
                    dataValueHandler={receiver}
                    setDataValueHandler={setReceiver}
                    datas={receiverRows}
                    loadByList='true'
                  />
                )}

                {Boolean(dataGridLoaded) && (
                  <FilterCheckBox
                    title="Vessel (BL)"
                    width="200"
                    api="vessel/list"
                    apiParam=""
                    apiParamValue=""
                    creator={createVesselHandler}
                    setValueOfFilterAndSearch={setVesselHandler}
                    dataValueHandler={vessel}
                    setDataValueHandler={setVessel}
                    datas={vesselRows}
                    loadByList='true'
                  />
                )}

                {Boolean(dataGridLoaded) && (
                  <FilterCheckBox
                    title="Canceled"
                    width="200"
                    api=""
                    apiParam=""
                    apiParamValue=""
                    creator={createCancelHandler}
                    setValueOfFilterAndSearch={setCancelHandler}
                    dataValueHandler={cancel}
                    setDataValueHandler={setCancel}
                    datas={[{'id':0,'name':'No'}, {'id':1,'name':'Yes'}]}
                    loadByList='true'
                  />
                )}
              </Box>
            </div>
            <div className="contentList">
              <MissionsList data={{ filterModel, setFilterModel, rows, setRows, setTotalRows, loadingGrid, setLoadingGrid, vesselRows, setVesselRows, setSurveyRows, setPrincipalRows, setMissionRows, setPortRows, setInsuredRows, setReceiverRows, setDataGridLoaded, setVisibleRows, openSnackBar, setOpenSnackBar, initFilterMissions }} />
              <div id="listExport">
                <Button size="small" variant="outlined" startIcon={<FileDownloadIcon />} onClick={handleExport}>Export</Button>
              </div>
            </div>
          </div>
          
          <Menu />
              
          <Snackbar open={isDownload} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              File saved in your downloads folder.
            </Alert>
          </Snackbar>
        </div>
    )
}

export default Portal
